import { call, put, takeLatest } from 'redux-saga/effects';
import { get, httpDelete, post, put as apiPut } from '../../config/apiConfig';
import { KRITERIUM,
    DELETE_KRITERIUM,
    REGEL_ID,
    REGEL,
    REGEL_PRIORITY_UPDATE,
    POST_KJOR_REGEL,
    POST_OPPHEV_REGEL,
    AVSTEMMER_AVAILABLE } from '../../constants/endPoints';
import { displayErrorMessage } from '../actions/errorActions';
import { DELETE_KRITERIER_REQUEST,
    fetchRegelRequest,
    fetchReglerRequest,
    fetchRegelSuccess,
    fetchReglerSuccess,
    kriteriumDeleteSuccess,
    REGEL_FETCH_REQUEST,
    REGLER_FETCH_REQUEST,
    SAVE_NEW_BETINGELSE_REQUEST,
    SAVE_NEW_REGEL_REQUEST,
    UPDATE_REGEL_PRIORITY,
    REGEL_TOGGLE_ACTIVE_REQUEST,
    toggleRegelActiveSuccess,
    UPDATE_REGEL_REQUEST,
    addUpdateElimineringKriteriumSuccess,
    fetchRegelFailure,
    AVSTEMMING_KRITERIUM_UPDATE_REQUEST,
    KJOR_REGEL,
    REGEL_TOGGLE_MVA_REQUEST,
    toggleRegelMvaSuccess,
    OPPHEV_FOR_REGEL_REQUEST,
    FETCH_AVSTEMMER_AVAILABLE, avstemmerIsAvailableSuccess } from '../actions/regelActions';
import NOB from '../../constants/languageStrings';

function* fetchRegler() {
    try {
        const response = yield call(get, REGEL);
        yield put(fetchReglerSuccess(response.data));
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.FETCH_REGLER_FAILED));
    }
}

function* fetchRegel({ regelId }) {
    try {
        const response = yield call(get, REGEL_ID(regelId));
        yield put(fetchRegelSuccess(response.data.kriteriumPar));
    } catch (error) {
        yield put(fetchRegelFailure());
        yield put(displayErrorMessage(NOB.ERROR.FETCH_REGLER_FAILED));
    }
}

function* saveRegel({ tittel, status, prioritet, mva }) {
    try {
        yield call(post, REGEL, { tittel, regelStatus: status, kriteriumPar: [], prioritet, usePostbruttoBel: mva });
        yield put(fetchReglerRequest());
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.SAVE_REGEL_FAILED));
    }
}

function* saveBetingelse({ regel, betingelse, motbetingelse }) {
    try {
        yield call(post, KRITERIUM(regel.regelId), { betingelse, motbetingelse });
        yield put(fetchRegelRequest(regel.regelId, 'AUTOMATISK_AVSTEMMING'));
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.SAVE_REGEL_FAILED));
    }
}

function* deleteKriterier({ regelId, kriteriumId }) {
    try {
        yield call(httpDelete, DELETE_KRITERIUM(kriteriumId));
        yield put(kriteriumDeleteSuccess(kriteriumId));
        yield put(fetchRegelRequest(regelId));
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.DELETE_KRITERIUM_FAILED));
    }
}

function* updateRegelPriority({ regler }) {
    try {
        yield call(apiPut, REGEL_PRIORITY_UPDATE, regler, { headers: { 'Content-Type': 'application/json' } });
        yield put(fetchReglerRequest());
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.CHANGE_PRIORITY_FAILED));
    }
}

function* toggleRegelActive({ regel }) {
    const regelWithNewStatus = regel.regelStatus === true ? { ...regel, regelStatus: false } : { ...regel, regelStatus: true };
    try {
        yield call(apiPut, REGEL, regelWithNewStatus, { headers: { 'Content-Type': 'application/json' } });
        yield put(toggleRegelActiveSuccess());
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.UPDATE_REGEL_FAILED));
    }
}

function* toggleRegelMva({ regel }) {
    const regelWithNewStatus = regel.usePostbruttoBel === true ? { ...regel, usePostbruttoBel: false } : { ...regel, usePostbruttoBel: true };
    try {
        yield call(apiPut, REGEL, regelWithNewStatus, { headers: { 'Content-Type': 'application/json' } });
        yield put(toggleRegelMvaSuccess());
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.UPDATE_REGEL_FAILED));
    }
}

function* updateRegel({ regel }) {
    try {
        yield call(apiPut, REGEL, regel, { headers: { 'Content-Type': 'application/json' } });
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.UPDATE_REGEL_FAILED));
    }
}

function* avstemmingBetingelseOppdatering({ regel, kriterium }) {
    try {
        yield call(post, KRITERIUM(regel.regelId), kriterium);
        yield put(addUpdateElimineringKriteriumSuccess());
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.UPDATE_KRITERIUM_FAILED));
    }
}

function* kjorRegel({ regelId }) {
    try {
        yield call(post, POST_KJOR_REGEL(regelId));
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.RUN_REGEL_FAILED));
    }
}

function* opphevAvstemmingForRegel({ regelId }) {
    try {
        yield call(post, POST_OPPHEV_REGEL(regelId));
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.OPPHEV_REGEL_FAILED));
    }
}

function* fetchAvstemmerAvailable() {
    try {
        const response = yield call(get, AVSTEMMER_AVAILABLE);
        yield put(avstemmerIsAvailableSuccess(response.data));
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.FETCH_AVSTEMMER_AVAILABLE));
    }
}

export default [
    takeLatest(REGLER_FETCH_REQUEST, fetchRegler),
    takeLatest(REGEL_FETCH_REQUEST, fetchRegel),
    takeLatest(SAVE_NEW_REGEL_REQUEST, saveRegel),
    takeLatest(SAVE_NEW_BETINGELSE_REQUEST, saveBetingelse),
    takeLatest(DELETE_KRITERIER_REQUEST, deleteKriterier),
    takeLatest(UPDATE_REGEL_PRIORITY, updateRegelPriority),
    takeLatest(REGEL_TOGGLE_ACTIVE_REQUEST, toggleRegelActive),
    takeLatest(REGEL_TOGGLE_MVA_REQUEST, toggleRegelMva),
    takeLatest(UPDATE_REGEL_REQUEST, updateRegel),
    takeLatest(AVSTEMMING_KRITERIUM_UPDATE_REQUEST, avstemmingBetingelseOppdatering),
    takeLatest(KJOR_REGEL, kjorRegel),
    takeLatest(OPPHEV_FOR_REGEL_REQUEST, opphevAvstemmingForRegel),
    takeLatest(FETCH_AVSTEMMER_AVAILABLE, fetchAvstemmerAvailable),
];
