// Action-types
export const AVSTEMMING_INF_SCROLL_FETCH_REQUEST = 'AVSTEMMING_INF_SCROLL_FETCH_REQUEST';
export const AVSTEMMING_INF_SCROLL_FETCH_SUCCESS = 'AVSTEMMING_INF_SCROLL_FETCH_SUCCESS';


export const AVSTEMMING_POST_REQUEST = 'AVSTEMMING_POST_REQUEST';
export const AVSTEMMING_POST_SUCCESS = 'AVSTEMMING_POST_SUCCESS';
export const AVSTEMMING_POST_FAILED = 'AVSTEMMING_POST_FAILED';
export const AVSTEMMING_CONFIRMATION_VISIBLE = 'AVSTEMMING_CONFIRMATION_VISIBLE';

export const SELSKAP_LIST_FETCH_REQUEST = 'SELSKAP_LIST_FETCH_REQUEST';
export const SELSKAP_LIST_FETCH_SUCCESS = 'SELSKAP_LIST_FETCH_SUCCESS';

export const PERIODER_FILTER_FETCH_REQUEST = 'PERIODER_FILTER_FETCH_REQUEST';
export const PERIODER_FILTER_FETCH_SUCCESS = 'PERIODER_FILTER_FETCH_SUCCESS';
export const PERIODER_FILTER_FETCH_FAILED = 'PERIODER_FILTER_FETCH_FAILED';

export const FETCH_SELSKAPER_FOR_MOTPART = 'SELSKAP_FILTER_CHANGED';
export const FETCH_SELSKAPER_FOR_MOTPART_SUCCESS = 'FETCH_SELSKAPER_FOR_MOTPART_SUCCESS';

export const SELSKAP_FAVOURITE_LIST_FETCH_REQUEST = 'SELSKAP_FAVOURITE_LIST_FETCH_REQUEST';

export const POSTKOMMENTAR_SAVE_REQUEST = 'POSTKOMMENTAR_SAVE_REQUEST';

export const POSTKOMMENTARER_FETCH_REQUEST = 'POSTKOMMENTARERER_FETCH_REQUEST';
export const POSTKOMMENTARER_FETCH_SUCCESS = 'POSTKOMMENTARER_FETCH_SUCCESS';

export const FILTER_CHANGED = 'FILTER_CHANGED';

export const FILTER_RESET = 'FILTER_RESET';

export const POST_HISTORY_FETCH_REQUEST = 'POST_HISTORY_FETCH_REQUEST';
export const POST_HISTORY_FETCH_SUCCESS = 'POST_HISTORY_FETCH_SUCCESS';

export const POST_OPPHEV_BUNKE_REQUEST = 'POST_OPPHEV_BUNKE_REQUEST';
export const POST_OPPHEV_BUNKE_SUCCESS = 'POST_OPPHEV_BUNKE_SUCCESS';
export const POST_OPPHEV_BUNKE_FAILED = 'POST_OPPHEV_BUNKE_FAILED';

// Action-creators
export const fetchAvstemmingInfScrollRequest = params => ({
    type: AVSTEMMING_INF_SCROLL_FETCH_REQUEST,
    params,
});

export const fetchAvstemmingInfScrollSuccess = () => ({
    type: AVSTEMMING_INF_SCROLL_FETCH_SUCCESS,
});

export const postManuellAvstemmingRequest = (postIder, kommentar, ignoreFravikelige) => ({
    type: AVSTEMMING_POST_REQUEST,
    postIder,
    kommentar,
    ignoreFravikelige,
});

export const postManuellAvstemmingSuccess = regelErrors => ({
    type: AVSTEMMING_POST_SUCCESS,
    regelErrors,
});

export const postManuellAvstemmingFailed = () => ({
    type: AVSTEMMING_POST_FAILED,
});

export const filterChanged = (id, value) => ({
    type: FILTER_CHANGED,
    id,
    value,
});

export const filterReset = () => ({
    type: FILTER_RESET,
});

export const fetchAllSelskaper = isFavoritter => ({
    type: SELSKAP_LIST_FETCH_REQUEST,
    isFavoritter,
});

export const fetchAllSelskaperSuccess = allSelskapList => ({
    type: SELSKAP_LIST_FETCH_SUCCESS,
    allSelskapList,
});

export const favouriteChecked = checked => ({
    type: SELSKAP_FAVOURITE_LIST_FETCH_REQUEST,
    checked,
});

export const fetchPerioder = periodestatus => ({
    type: PERIODER_FILTER_FETCH_REQUEST,
    periodestatus,
});

export const fetchPerioderSuccess = perioder => ({
    type: PERIODER_FILTER_FETCH_SUCCESS,
    perioder,
});

export const fetchPerioderFailed = errorMessage => ({
    type: PERIODER_FILTER_FETCH_FAILED,
    errorMessage,
});

export const fetchPostHistoryRequest = postId => ({
    type: POST_HISTORY_FETCH_REQUEST,
    postId,
});

export const fetchPostHistorySuccess = bunke => ({
    type: POST_HISTORY_FETCH_SUCCESS,
    bunke,
});

export const selskapFilterChanged = selskapNr => ({
    type: FETCH_SELSKAPER_FOR_MOTPART,
    selskapNr,
});

export const fetchMotparterForSelskapSuccess = motpartselskaper => ({
    type: FETCH_SELSKAPER_FOR_MOTPART_SUCCESS,
    motpartselskaper,
});

export const savePostkommentarRequest = (postId, kommentar) => ({
    type: POSTKOMMENTAR_SAVE_REQUEST,
    postId,
    kommentar,
});

export const fetchPostkommentarerRequest = postId => ({
    type: POSTKOMMENTARER_FETCH_REQUEST,
    postId,
});

export const fetchPostkommentarerSuccess = postkommentarer => ({
    type: POSTKOMMENTARER_FETCH_SUCCESS,
    postkommentarer,
});

export const postOpphevBunkeRequest = (bunkeId, postId) => ({
    type: POST_OPPHEV_BUNKE_REQUEST,
    bunkeId,
    postId,
});

export const opphevBunkeSuccess = bunkeId => ({
    type: POST_OPPHEV_BUNKE_SUCCESS,
    bunkeId,
});

export const opphevBunkeFailed = () => ({
    type: POST_OPPHEV_BUNKE_FAILED,
});

export const avstemmingConfirmationVisible = (isVisible, regelErrors) => ({
    type: AVSTEMMING_CONFIRMATION_VISIBLE,
    isVisible,
    regelErrors,
});
