import { DELETE_KRITERIER_SUCCESS,
    REGEL_FETCH_SUCCESS,
    REGLER_FETCH_SUCCESS,
    REGEL_TOGGLE_ACTIVE_REQUEST,
    REGEL_TOGGLE_ACTIVE_SUCCESS,
    REGEL_FETCH_REQUEST,
    REGEL_FETCH_FAILURE,
    REGEL_TOGGLE_MVA_SUCCESS,
    FETCH_AVSTEMMER_AVAILABLE_SUCCESS } from '../actions/regelActions';

const initialState = {
    regler: [],
    kriterier: [],
    toggleActiveSuccess: false,
    toggleMvaSuccess: false,
    isFetchingBetingelser: false,
    avstemmerIsAvailable: false,
};

export default function RegelReducer(prevState = initialState, action) {
    switch (action.type) {
        case REGLER_FETCH_SUCCESS:
            return {
                ...prevState,
                regler: action.regler,
            };
        case REGEL_FETCH_REQUEST:
            return {
                ...prevState,
                isFetchingBetingelser: true,
            };
        case REGEL_FETCH_SUCCESS:
            return {
                ...prevState,
                kriterier: action.kriterier,
                isFetchingBetingelser: false,
            };
        case REGEL_FETCH_FAILURE:
            return {
                ...prevState,
                isFetchingBetingelser: false,
            };
        case DELETE_KRITERIER_SUCCESS:
            return {
                ...prevState,
                kriterier: prevState.kriterier.filter(kriterium => kriterium.kriteriumParId !== action.kriteriumId),
            };
        case REGEL_TOGGLE_ACTIVE_REQUEST:
            return {
                ...prevState,
                toggleActiveSuccess: false,
            };
        case REGEL_TOGGLE_ACTIVE_SUCCESS:
            return {
                ...prevState,
                toggleActiveSuccess: true,
            };
        case REGEL_TOGGLE_MVA_SUCCESS:
            return {
                ...prevState,
                toggleMvaSuccess: true,
            };
        case FETCH_AVSTEMMER_AVAILABLE_SUCCESS:
            return {
                ...prevState,
                avstemmerIsAvailable: action.status,
            };

        default:
            return prevState;
    }
}
