// Action-types
export const SELSKAP_FETCH_REQUEST = 'SELSKAP_FETCH_REQUEST';
export const SELSKAP_FETCH_SUCCESS = 'SELSKAP_FETCH_SUCCESS';
export const SELSKAP_FETCH_FAILED = 'SELSKAP_FETCH_FAILED';

export const SELSKAP_INFO_FETCH_REQUEST = 'SELSKAP_INFO_FETCH_REQUEST';
export const SELSKAP_INFO_FETCH_SUCCESS = 'SELSKAP_INFO_FETCH_SUCCESS';
export const SELSKAP_INFO_FETCH_FAILED = 'SELSKAP_INFO_FETCH_FAILED';

export const PERIODER_FETCH_REQUEST = 'PERIODER_FETCH_REQUEST';
export const PERIODER_FETCH_SUCCESS = 'PERIODER_FETCH_SUCCESS';
export const PERIODER_FETCH_FAILED = 'PERIODER_FETCH_FAILED';

export const NAV_TO_MANUELL_AVSTEMMING_REQUEST = 'NAV_TO_MANUELL_AVSTEMMING_REQUEST';
export const NAV_TO_MANUELL_AVSTEMMING_FAILED = 'NAV_TO_MANUELL_AVSTEMMING_FAILED';

export const TOGGLE_FAVOURITE_SELSKAP_REQUEST = 'TOGGLE_FAVOURITE_SELSKAP_REQUEST';
export const TOGGLE_FAVOURITE_SELSKAP_SUCCESS = 'TOGGLE_FAVOURITE_SELSKAP_SUCCESS';

// Action-creators
export const fetchSelskapList = periode => ({
    type: SELSKAP_FETCH_REQUEST,
    periode,
});

export const fetchSelskapListSuccess = selskapList => ({
    type: SELSKAP_FETCH_SUCCESS,
    selskapList,
});

export const fetchSelskapListFailed = errorMessage => ({
    type: SELSKAP_FETCH_FAILED,
    errorMessage,
});

export const fetchSelskapInfo = (periode, selskapNr) => ({
    type: SELSKAP_INFO_FETCH_REQUEST,
    periode,
    selskapNr,
});

export const fetchSelskapInfoSuccess = selskapInfo => ({
    type: SELSKAP_INFO_FETCH_SUCCESS,
    selskapInfo,
});

export const fetchSelskapInfoFailed = errorMessage => ({
    type: SELSKAP_INFO_FETCH_FAILED,
    errorMessage,
});

export const fetchPerioder = periodestatus => ({
    type: PERIODER_FETCH_REQUEST,
    periodestatus,
});

export const fetchPerioderSuccess = perioder => ({
    type: PERIODER_FETCH_SUCCESS,
    perioder,
});

export const fetchPerioderFailed = errorMessage => ({
    type: PERIODER_FETCH_FAILED,
    errorMessage,
});

export const navToManuellAvstemming = () => ({
    type: NAV_TO_MANUELL_AVSTEMMING_REQUEST,
});

export const navToManuellAvstemmingFailed = errorMessage => ({
    type: NAV_TO_MANUELL_AVSTEMMING_FAILED,
    errorMessage,
});

export const favouriteToggled = selskap => ({
    type: TOGGLE_FAVOURITE_SELSKAP_REQUEST,
    selskap,
});

export const favouriteToggledSuccess = (selskapNr, value) => ({
    type: TOGGLE_FAVOURITE_SELSKAP_SUCCESS,
    selskapNr,
    value,
});
