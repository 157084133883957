export const typeFilter = [
    {
        name: 'Alle',
        value: '',
    },
    {
        name: 'RES',
        value: 'RES',
    },
    {
        name: 'BAL',
        value: 'BAL',
    },
];

export const avstemtFilter = [
    {
        name: 'Alle',
        value: '',
    },
    {
        name: 'Avstemt',
        value: 'true',
    },
    {
        name: 'Ikke Avstemt',
        value: 'false',
    },
];

export const avstemtTypeFilter = [
    {
        name: 'Alle',
        value: '',
    },
    {
        name: 'Manuell',
        value: 'false',
    },
    {
        name: 'Automatisk',
        value: 'true',
    },
];

export const elimineringFilters = {
    SELSKAP_NR: 'selskapNr',
    MOTPART_NR: 'motpartselskapNr',
    REGEL_ID: 'regelId',
    KONSERNKONTO_NR_FRA: 'konsernkontoNrFra',
    KONSERNKONTO_NR_TIL: 'konsernkontoNrTil',
    KONTO_NR_FRA: 'kontoNrFra',
    KONTO_NR_TIL: 'kontoNrTil',
    POST_TP: 'postTp',
    FAKTURANUMMER: 'fakturaNr',
    HOVEDKILDE_KD: 'hovedkildeKd',
    AVSTEMMING_TP: 'automatiskAvstemt',
    BELOP_FRA: 'belopFra',
    BELOP_TIL: 'belopTil',
    BRUTTOBELOP_FRA: 'bruttobelopFra',
    BRUTTOBELOP_TIL: 'bruttobelopTil',
    BUNKE_ID: 'bunkeId',
};
