import { FETCH_IG_KONTOER_SUCCESS, FETCH_KONSERNKONTOER_SUCCESS, FETCH_KONTOKOMBINASJON_SUCCESS } from '../actions/kontoplanActions';

const initialState = {
    igKontoer: [],
    konsernkontoer: [],
    kontokombinasjon: [],
};

export default function KontoplanReducer(prevstate = initialState, action) {
    switch (action.type) {
        case FETCH_IG_KONTOER_SUCCESS:
            return {
                ...prevstate,
                igKontoer: action.igKontoer.map(konto => ({
                    ...konto,
                    igKontoDisplayNavn: `${konto.kontonavn} - ${konto.kontoNr}`,
                })),
            };
        case FETCH_KONSERNKONTOER_SUCCESS:
            return {
                ...prevstate,
                konsernkontoer: action.konsernkontoer,
            };
        case FETCH_KONTOKOMBINASJON_SUCCESS:
            return {
                ...prevstate,
                kontokombinasjon: action.kontokombinasjon.map(konto => ({ ...konto, ompostering: !!konto.omposteringKonto })),
            };
        default:
            return prevstate;
    }
}
