import { HAS_LATEST_EXPORT } from '../../constants/localStorageKeys';
import firstDateIsLater from '../../utils/comparatorUtils';
import { remove, set, exists, retrieve } from '../../utils/localStorageHelper';
import { APPROVE_HFM_EXPORT_REQUEST,
    APPROVE_HFM_EXPORT_SUCCESS, CHECK_RUN_REGEL_EFFECT_REQUEST, CHECK_RUN_REGEL_EFFECT_SUCCESS,
    CREATE_HFM_EXPORT_REQUEST,
    CREATE_HFM_EXPORT_SUCCESS,
    FETCH_LATEST_HFM_EXPORT_REQUEST,
    FETCH_LATEST_HFM_EXPORT_SUCCESS, FETCH_SLETTEREGLER_SUCCESS,
    FETCH_VALIDERINGSBEGRUNNELSER_SUCCESS, GJENOPPRETT_POSTER_SUCCESS, RUN_REGEL_SUCCESS } from '../actions/adminActions';
import { DISPLAY_ERROR_MESSAGE } from '../actions/errorActions';

const initialState = {
    valideringsbegrunnelser: [],
    approveHfmExportIsFetching: false,
    isFetchingLatestExport: false,
    createHfmExportPending: false,
    hfmExportMetadata: {
        hfmEksportId: '',
        kvartal: '',
        opprettetDtTd: '',
        opprettetSgn: '',
    },
    isLatestExport: true,
    sletteregler: [],
    gjenopprettPosterSuccess: false,
    runSletteregelSuccess: false,
    amountOfPoster: null,
    checkRunRegelIsFetching: false,
};

export default function AdminReducer(prevState = initialState, action) {
    const { type, amountOfPoster, sletteregler, exportMetadata, valideringsbegrunnelser } = action;
    switch (type) {
        case FETCH_VALIDERINGSBEGRUNNELSER_SUCCESS:
            return {
                ...prevState,
                valideringsbegrunnelser,
            };
        case CREATE_HFM_EXPORT_REQUEST:
            return {
                ...prevState,
                createHfmExportPending: true,
            };
        case CREATE_HFM_EXPORT_SUCCESS:
            set(HAS_LATEST_EXPORT, false);
            return {
                ...prevState,
                isLatestExport: false,
                createHfmExportPending: false,
            };
        case FETCH_LATEST_HFM_EXPORT_REQUEST:
            return {
                ...prevState,
                isLatestExport: exists(HAS_LATEST_EXPORT) ? retrieve(HAS_LATEST_EXPORT) : prevState.isLatestExport,
                isFetchingLatestExport: true,
            };
        case FETCH_LATEST_HFM_EXPORT_SUCCESS:
            // eslint-disable-next-line no-case-declarations
            const firstIsLater = firstDateIsLater(exportMetadata.opprettetDtTd, prevState.hfmExportMetadata.opprettetDtTd);
            if (firstIsLater) remove(HAS_LATEST_EXPORT);

            return {
                ...prevState,
                hfmExportMetadata: exportMetadata || prevState.hfmExportMetadata,
                isLatestExport: prevState.isLatestExport || firstIsLater,
                isFetchingLatestExport: false,
            };
        case APPROVE_HFM_EXPORT_REQUEST:
            return {
                ...prevState,
                approveHfmExportIsFetching: true,
            };
        case APPROVE_HFM_EXPORT_SUCCESS:
            return {
                ...prevState,
                approveHfmExportIsFetching: false,
            };
        case DISPLAY_ERROR_MESSAGE:
            return {
                ...prevState,
                approveHfmExportIsFetching: false,
            };
        case FETCH_SLETTEREGLER_SUCCESS:
            return {
                ...prevState,
                sletteregler,
            };
        case GJENOPPRETT_POSTER_SUCCESS:
            return {
                ...prevState,
                gjenopprettPosterSuccess: true,
            };
        case RUN_REGEL_SUCCESS:
            return {
                ...prevState,
                runSletteregelSuccess: true,
                amountOfPoster: null,
            };
        case CHECK_RUN_REGEL_EFFECT_REQUEST:
            return {
                ...prevState,
                checkRunRegelIsFetching: true,
            };
        case CHECK_RUN_REGEL_EFFECT_SUCCESS:
            return {
                ...prevState,
                amountOfPoster,
                checkRunRegelIsFetching: false,
            };
        default:
            return prevState;
    }
}
