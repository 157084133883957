import { call, put, select, takeLatest } from 'redux-saga/effects';
import { get, post, queryParams, queryParamsFromList } from '../../config/apiConfig';
import { displayErrorMessage, displayErrorList } from '../actions/errorActions';
import { postManuellAvstemmingSuccess,
    AVSTEMMING_POST_REQUEST,
    fetchAllSelskaperSuccess,
    SELSKAP_LIST_FETCH_REQUEST,
    PERIODER_FILTER_FETCH_REQUEST,
    fetchPerioderSuccess,
    AVSTEMMING_INF_SCROLL_FETCH_REQUEST,
    FETCH_SELSKAPER_FOR_MOTPART,
    fetchMotparterForSelskapSuccess,
    SELSKAP_FAVOURITE_LIST_FETCH_REQUEST,
    fetchPostkommentarerSuccess,
    POSTKOMMENTAR_SAVE_REQUEST,
    POSTKOMMENTARER_FETCH_REQUEST,
    fetchPostkommentarerRequest,
    POST_HISTORY_FETCH_REQUEST,
    fetchPostHistorySuccess,
    opphevBunkeSuccess,
    POST_OPPHEV_BUNKE_REQUEST,
    avstemmingConfirmationVisible,
    fetchPostHistoryRequest,
    postManuellAvstemmingFailed,
    opphevBunkeFailed,
    fetchAvstemmingInfScrollSuccess } from '../actions/manuellAvstemmingAction';
import { GET_SELSKAP_LIST,
    GET_PERIODER,
    GET_POSTER,
    GET_HISTORY,
    GET_MOTPARTER_FOR_SELSKAP,
    POSTKOMMENTAR,
    OPPHEV_BUNKE,
    AVSTEM_POSTER } from '../../constants/endPoints';
import NOB from '../../constants/languageStrings';
import { PAGE_SIZE } from '../../constants/appConstants';

function* fetchAvstemmingsListInfiniteScroll({ params }) {
    try {
        const filters = yield select(state => state.manuellAvstemming.filterOptions);
        const PAGE_NUMBER = Math.floor(params.request.endRow / PAGE_SIZE) - 1;
        const { sortModel } = params.request;
        const response = yield call(get, GET_POSTER + queryParams({ ...filters,
            pageNumber: PAGE_NUMBER,
            pageSize: PAGE_SIZE,
            sortField: sortModel.length > 0 ? sortModel[0].colId : '',
            sortDirection: sortModel.length > 0 ? sortModel[0].sort.toUpperCase() : '' }));

        const lastRowIndex = response.data.length === PAGE_SIZE ? null : PAGE_NUMBER * PAGE_SIZE + response.data.length;

        params.success({ rowData: response.data.map(data => ({
            ...data,
            isMotpart: data.selskapNr !== filters.selskapNr,
        })),
        rowCount: lastRowIndex });
        yield put(fetchAvstemmingInfScrollSuccess());
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.POST_LIST_FAILED));
        params.fail();
    }
}

function* postAvstemmingInfo({ postIder, kommentar, ignoreFravikelige }) {
    try {
        const response = yield call(post, `${AVSTEM_POSTER
            + queryParamsFromList('postId', postIder)}&ignoreFravikelige=${ignoreFravikelige}`,
        kommentar,
        { headers: { 'Content-Type': 'text/plain' } });

        const errors = response.data;
        const ufravikeligeErrors = errors.filter(error => !error.fravikelig).map(error => error.message);

        if (ufravikeligeErrors.length > 0) {
            yield put(displayErrorList(ufravikeligeErrors));
            yield put(avstemmingConfirmationVisible(false));
        } else if (errors.length === 0 || ignoreFravikelige) {
            yield put(avstemmingConfirmationVisible(false));
            yield put(postManuellAvstemmingSuccess(response.data));
        } else {
            yield put(avstemmingConfirmationVisible(true, errors));
        }
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.POST_AVSTEM_FAILED));
        yield put(avstemmingConfirmationVisible(false, false));
        yield put(postManuellAvstemmingFailed());
    }
}

function* fetchAllSelskaper() {
    try {
        const favourite = yield select(state => state.manuellAvstemming.favouriteCheckboxChecked);
        const response = yield call(get, GET_SELSKAP_LIST + queryParams({ onlyFavoritter: favourite }));
        yield put(fetchAllSelskaperSuccess(response.data));
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.SELSKAP_LIST_FAILED));
    }
}

function* fetchPerioder({ periodestatus }) {
    try {
        const response = yield call(get, GET_PERIODER(periodestatus));
        yield put(fetchPerioderSuccess(response.data));
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.PERIODER_FAILED));
    }
}

function* fetchPostHistory({ postId }) {
    try {
        const response = yield call(get, GET_HISTORY(postId));
        yield put(fetchPostHistorySuccess(response.data));
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.POST_HISTORY_FAILED));
    }
}

function* fetchMotparterForSelskap({ selskapNr }) {
    try {
        const filters = yield select(state => state.manuellAvstemming.filterOptions);
        const selskaper = yield call(get, GET_MOTPARTER_FOR_SELSKAP(selskapNr) + queryParams(
            { avstemt: filters.avstemt, periodeFra: filters.periodeFra, periodeTil: filters.periodeTil },
        ));
        yield put(fetchMotparterForSelskapSuccess(selskaper.data));
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.MOTPARTER_FAILED));
    }
}

function* fetchPostkommentarer({ postId }) {
    try {
        const response = yield call(get, POSTKOMMENTAR(postId));
        yield put(fetchPostkommentarerSuccess(response.data));
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.FETCH_POSTKOMMENTAR_FAILED));
    }
}

function* savePostkommentar({ postId, kommentar }) {
    try {
        yield call(post, POSTKOMMENTAR(postId), kommentar, { headers: { 'Content-Type': 'text/plain' } });
        yield put(fetchPostkommentarerRequest(postId));
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.SAVE_POSTKOMMENTAR_FAILED));
    }
}

function* opphevBunke({ bunkeId, postId }) {
    try {
        yield call(post, OPPHEV_BUNKE(bunkeId));
        yield put(opphevBunkeSuccess());

        if (postId) {
            yield put(fetchPostHistoryRequest(postId));
        }
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.OPPHEV_BUNKE_FAILED));
        yield put(opphevBunkeFailed());
    }
}


export default [
    takeLatest(AVSTEMMING_INF_SCROLL_FETCH_REQUEST, fetchAvstemmingsListInfiniteScroll),
    takeLatest(AVSTEMMING_POST_REQUEST, postAvstemmingInfo),
    takeLatest(SELSKAP_LIST_FETCH_REQUEST, fetchAllSelskaper),
    takeLatest(SELSKAP_FAVOURITE_LIST_FETCH_REQUEST, fetchAllSelskaper),
    takeLatest(PERIODER_FILTER_FETCH_REQUEST, fetchPerioder),
    takeLatest(FETCH_SELSKAPER_FOR_MOTPART, fetchMotparterForSelskap),
    takeLatest(POSTKOMMENTAR_SAVE_REQUEST, savePostkommentar),
    takeLatest(POSTKOMMENTARER_FETCH_REQUEST, fetchPostkommentarer),
    takeLatest(POST_HISTORY_FETCH_REQUEST, fetchPostHistory),
    takeLatest(POST_OPPHEV_BUNKE_REQUEST, opphevBunke),
];
