import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import AuthenticationReducer from './authenticationReducer';
import { DashboardMasterReducer, DashboardSlaveReducer, PeriodeReducer } from './dashboardReducer';
import { ManuellAvstemmingReducer, PeriodeFilterReducer } from './manuellAvstemmingReducer';
import ErrorReducer from './errorReducer';
import RegelReducer from './regelReducer';
import AdminReducer from './adminReducer';
import AutomatiskAvstemmingReducer from './automatiskAvstemmingReducer';
import KontoplanReducer from './kontoplanReducer';
import ManuellElimineringReducer from './manuellElimineringReducer';
import ElimineringReducer from './elimineringReducer';
import AutomatiskElimineringReducer from './automatiskElimineringReducer';

export default history => combineReducers({
    authentication: AuthenticationReducer,
    dashboardMaster: DashboardMasterReducer,
    dashboardSlave: DashboardSlaveReducer,
    PeriodeFilter: PeriodeFilterReducer,
    manuellAvstemming: ManuellAvstemmingReducer,
    periodeData: PeriodeReducer,
    error: ErrorReducer,
    regel: RegelReducer,
    admin: AdminReducer,
    automatiskAvstemming: AutomatiskAvstemmingReducer,
    kontoplan: KontoplanReducer,
    manuellEliminering: ManuellElimineringReducer,
    eliminering: ElimineringReducer,
    automatiskEliminering: AutomatiskElimineringReducer,
    router: connectRouter(history),
});
