import { BUNKE_HISTORIKK_SUCCESS, BUNKE_IDS_CHANGED, ELIMINER_POSTER_FAILURE, ELIMINER_POSTER_REQUEST,
    ELIMINER_POSTER_SUCCESS,
    EXPORT_BUNKER_FAILED,
    EXPORT_BUNKER_REQUEST,
    EXPORT_BUNKER_SUCCESS,
    FETCH_BUNKE_SUCCESS,
    FETCH_FEILBUNKER_FAILURE,
    FETCH_FEILBUNKER_REQUEST,
    FETCH_FEILBUNKER_SUCCESS,
    FETCH_IKKE_ELIMINERTE_BUNKER_SUCCESS,
    OVERSIKT_ROW_CLICKED,
    POST_OPPHEV_BUNKE_ELIMINERING_FAILED,
    POST_OPPHEV_BUNKE_ELIMINERING_REQUEST,
    POST_OPPHEV_BUNKE_ELIMINERING_SUCCESS,
    REMOVE_FROM_FEILTABELL_REQUEST,
    REMOVE_FROM_FEILTABELL_SUCCESS,
    SEND_TO_FEILTABELL_SUCCESS } from '../actions/manuellElimineringActions';
import { DISPLAY_ERROR_MESSAGE } from '../actions/errorActions';

const initialState = {
    ikkeEliminerteBunker: [],
    bunkeIds: [],
    bunke: {},
    poster: [],
    feilbunker: [],
    aktuellBunkeId: undefined,
    eliminerPosterIsFetching: false,
    eliminerPosterSuccess: false,
    isFetchingFeilbunker: false,
    isRemovingFromFeiltabell: false,
    isOpphevingBunke: false,
    bunkerExportIsFetching: false,
    eliminerteBunker: [],
};

function ManuellElimineringReducer(prevState = initialState, action) {
    switch (action.type) {
        case ELIMINER_POSTER_REQUEST:
            return {
                ...prevState,
                eliminerPosterIsFetching: true,
                eliminerPosterSuccess: false,
            };
        case FETCH_IKKE_ELIMINERTE_BUNKER_SUCCESS:
            return {
                ...prevState,
                ikkeEliminerteBunker: action.ikkeEliminerteBunker,
                bunkeIds: action.ikkeEliminerteBunker.map(bunke => bunke.bunkeId),
            };
        case FETCH_BUNKE_SUCCESS:
            return {
                ...prevState,
                bunke: action.bunke,
                poster: action.bunke.poster.map(post => {
                    if (post.behandletEliminering && !post.eliminert) {
                        return { ...post, oppdatertPostBel: 0 };
                    }
                    return { ...post };
                }),
                aktuellBunkeId: action.bunke.bunkestatus === 'FEIL' ? undefined : action.bunke.bunkeId,
            };
        case OVERSIKT_ROW_CLICKED:
            return {
                ...prevState,
                aktuellBunkeId: action.bunkeId,
            };
        case ELIMINER_POSTER_SUCCESS:
            return {
                ...prevState,
                eliminerPosterIsFetching: false,
                eliminerPosterSuccess: true,
            };
        case ELIMINER_POSTER_FAILURE:
            return {
                ...prevState,
                eliminerPosterSuccess: false,
            };
        case DISPLAY_ERROR_MESSAGE:
            return {
                ...prevState,
                eliminerPosterIsFetching: false,
            };
        case FETCH_FEILBUNKER_REQUEST:
            return {
                ...prevState,
                isFetchingFeilbunker: true,
            };
        case FETCH_FEILBUNKER_SUCCESS:
            return {
                ...prevState,
                isFetchingFeilbunker: false,
                feilbunker: action.bunker,
            };
        case FETCH_FEILBUNKER_FAILURE:
            return {
                ...prevState,
                isFetchingFeilbunker: false,
            };
        case REMOVE_FROM_FEILTABELL_REQUEST:
            return {
                ...prevState,
                isRemovingFromFeiltabell: true,
            };
        case REMOVE_FROM_FEILTABELL_SUCCESS:
            return {
                ...prevState,
                isRemovingFromFeiltabell: false,
            };
        case SEND_TO_FEILTABELL_SUCCESS:
            return {
                ...prevState,
                ikkeEliminerteBunker: prevState.ikkeEliminerteBunker.filter(bunker => bunker.bunkeId !== action.bunkeId),
            };
        case POST_OPPHEV_BUNKE_ELIMINERING_REQUEST:
            return {
                ...prevState,
                isOpphevingBunke: true,
            };
        case POST_OPPHEV_BUNKE_ELIMINERING_SUCCESS:
            return {
                ...prevState,
                feilbunker: prevState.feilbunker.filter(bunker => bunker.bunkeId !== action.bunkeId),
                isOpphevingBunke: false,
            };
        case POST_OPPHEV_BUNKE_ELIMINERING_FAILED:
            return {
                ...prevState,
                isOpphevingBunke: false,
            };
        case EXPORT_BUNKER_REQUEST:
            return {
                ...prevState,
                bunkerExportIsFetching: true,
            };
        case EXPORT_BUNKER_SUCCESS:
            return {
                ...prevState,
                bunkerExportIsFetching: false,
            };
        case EXPORT_BUNKER_FAILED:
            return {
                ...prevState,
                bunkerExportIsFetching: false,
            };
        case BUNKE_IDS_CHANGED:
            return {
                ...prevState,
                bunkeIds: action.bunkeIds,
            };
        case BUNKE_HISTORIKK_SUCCESS:
            return {
                ...prevState,
                eliminerteBunker: action.bunker,
            };
        default:
            return prevState;
    }
}

export default ManuellElimineringReducer;
