import { SELSKAP_FETCH_REQUEST,
    SELSKAP_FETCH_SUCCESS,
    SELSKAP_INFO_FETCH_SUCCESS,
    PERIODER_FETCH_SUCCESS,
    TOGGLE_FAVOURITE_SELSKAP_SUCCESS } from '../actions/dashboardAction';

const initialState = {
    errorMessage: '',
    selskapList: [],
    selskapInfo: [],
    perioder: [],
    initialPeriode: { periodeId: 0, fraDato: '' },
};

export function DashboardMasterReducer(prevState = initialState, action) {
    switch (action.type) {
        case SELSKAP_FETCH_REQUEST:
            return {
                ...prevState,
            };
        case SELSKAP_FETCH_SUCCESS:
            return {
                ...prevState,
                selskapList: action.selskapList,
            };
        case TOGGLE_FAVOURITE_SELSKAP_SUCCESS:
            return { ...prevState,
                selskapList: prevState.selskapList
                    .map(selskap => selskap.selskapNr === action.selskapNr
                        ? { ...selskap, favoritt: action.value } : selskap) };
        default:
            return prevState;
    }
}

export function DashboardSlaveReducer(prevState = initialState, action) {
    switch (action.type) {
        case SELSKAP_INFO_FETCH_SUCCESS:
            return {
                ...prevState,
                selskapInfo: action.selskapInfo,
            };
        case SELSKAP_FETCH_SUCCESS:
            return {
                ...prevState,
                selskapInfo: [],
            };
        default:
            return prevState;
    }
}

export function PeriodeReducer(prevState = initialState, action) {
    switch (action.type) {
        case PERIODER_FETCH_SUCCESS:
            return {
                ...prevState,
                perioder: action.perioder,
                initialPeriode: action.perioder[0],
            };
        default:
            return prevState;
    }
}
