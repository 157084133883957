import { CLOSE_ERROR_MESSAGE, DISPLAY_ERROR_LIST, DISPLAY_ERROR_MESSAGE } from '../actions/errorActions';

const initialState = {
    message: '',
    messageList: [],
    visible: false,
};

export default function ErrorReducer(prevState = initialState, action) {
    const { type, errorMessage, errors } = action;
    switch (type) {
        case DISPLAY_ERROR_MESSAGE:
            return {
                ...prevState,
                message: errorMessage,
                visible: true,
            };
        case CLOSE_ERROR_MESSAGE:
            return {
                ...prevState,
                message: null,
                messageList: [],
                visible: false,
            };
        case DISPLAY_ERROR_LIST:
            return {
                ...prevState,
                messageList: errors,
                visible: true,
            };
        default:
            return prevState;
    }
}
