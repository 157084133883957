import { call, takeLatest, put, select } from 'redux-saga/effects';
import fileDownload from 'js-file-download';
import { get, getFile, post, put as apiPut, queryParams, httpDelete } from '../../config/apiConfig';
import { fetchValideringsbegrunnelserSuccess,
    FETCH_VALIDERINGSBEGRUNNELSER_REQUEST,
    saveValideringsbegrunnelseSuccess,
    fetchValideringsbegrunnelserRequest,
    SAVE_VALIDERINGSBEGRUNNELSE_REQUEST,
    updateValideringsbegrunnelseSuccess,
    UPDATE_VALIDERINGSBEGRUNNELSE_REQUEST,
    APPROVE_HFM_EXPORT_REQUEST,
    approveHFMExportSuccess,
    CREATE_HFM_EXPORT_REQUEST,
    DOWNLOAD_HFM_EXPORT_REQUEST,
    createHfmExportSuccess,
    fetchLatestHfmExportSuccess,
    fetchLatestHfmExportRequest,
    FETCH_LATEST_HFM_EXPORT_REQUEST,
    POLL_LATEST_HFM_EXPORT,
    FETCH_SLETTEREGLER_REQUEST,
    fetchSlettereglerSuccess,
    gjenopprettPosterSuccess,
    GJENOPPRETT_POSTER_REQUEST,
    UPDATE_SLETTEREGEL_REQUEST,
    DELETE_SLETTEREGEL_REQUEST,
    fetchSlettereglerRequest,
    ADD_SLETTEREGEL_REQUEST,
    RUN_REGEL_REQUEST,
    runRegelSuccess,
    CHECK_RUN_REGEL_EFFECT_REQUEST, checkRunRegelEffectSuccess } from '../actions/adminActions';
import { UPDATE_VALIDERINGSBEGRUNNELSE,
    VALIDERINGSBEGRUNNELSE,
    OPEN_NEW_PERIODER,
    CREATE_HFM_EXPORT,
    DOWNLOAD_HFM_EXPORT,
    SLETTEREGLER, GJENOPPRETT_POSTER, DELETE_SLETTEREGEL, RUN_SLETTEREGEL, CHECK_SLETTEREGEL } from '../../constants/endPoints';
import { displayErrorMessage } from '../actions/errorActions';
import NOB from '../../constants/languageStrings';
import { fetchPerioder } from './dashboardSaga';

function* fetchValideringsbegrunnelser() {
    try {
        const response = yield call(get, VALIDERINGSBEGRUNNELSE);
        yield put(fetchValideringsbegrunnelserSuccess(response.data));
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.FETCH_VALIDERINGSBEGRUNNELSER_FAILED));
    }
}

function* saveValideringsbegrunnelse({ valideringsbegrunnelse }) {
    try {
        yield call(post, VALIDERINGSBEGRUNNELSE, valideringsbegrunnelse, { headers: { 'Content-Type': 'text/plain' } });
        yield put(saveValideringsbegrunnelseSuccess());
        yield put(fetchValideringsbegrunnelserRequest());
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.SAVE_VALIDERINGSBEGRUNNELSER_FAILED));
    }
}

function* updateValideringsbegrunnelse({ id, valideringsbegrunnelse }) {
    try {
        yield call(apiPut, UPDATE_VALIDERINGSBEGRUNNELSE(id), valideringsbegrunnelse, { headers: { 'Content-Type': 'text/plain' } });
        yield put(updateValideringsbegrunnelseSuccess());
        yield put(fetchValideringsbegrunnelserRequest());
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.UPDATE_VALIDERINGSBEGRUNNELSER_FAILED));
    }
}

function* approveHFMExport() {
    try {
        yield call(post, OPEN_NEW_PERIODER);
        yield put(approveHFMExportSuccess());
        yield fetchPerioder({ periodestatus: null });
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.OPEN_NEW_PERIODE_FAILED));
    }
}


function* fetchLatestHfmExport() {
    try {
        const response = yield call(get, CREATE_HFM_EXPORT);
        yield put(fetchLatestHfmExportSuccess(response.data));
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.CREATE_HFM_EXPORT));
    }
}


function* createHfmExport() {
    try {
        yield call(post, CREATE_HFM_EXPORT);
        yield put(createHfmExportSuccess());
        yield put(fetchLatestHfmExportRequest());
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.CREATE_HFM_EXPORT));
    }
}

function* downloadHfmExport() {
    try {
        const { hfmEksportId, kvartal } = yield select(state => state.admin.hfmExportMetadata);
        const response = yield call(getFile, DOWNLOAD_HFM_EXPORT(hfmEksportId));
        fileDownload(response.data, `hfm_eksport_Q${kvartal}.xlsx`);
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.CREATE_HFM_EXPORT));
    }
}

function* fetchSletteregler() {
    try {
        const response = yield call(get, SLETTEREGLER);
        yield put(fetchSlettereglerSuccess(response.data));
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.FETCH_SLETTEREGLER));
    }
}

function* gjenopprettSletteregel({ sletteregelId, fromDate }) {
    try {
        yield call(post, GJENOPPRETT_POSTER(sletteregelId) + queryParams({ gjenopprettFraDato: fromDate.toISOString().split('T', 1)[0] }));
        yield put(gjenopprettPosterSuccess());
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.GJENOPPRETT_POSTER));
    }
}

function* addSletteregel({ betingelse, fromDate }) {
    try {
        yield call(post, SLETTEREGLER, { betingelse, aktiverFraDtTd: fromDate });
        yield put(fetchSlettereglerRequest());
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.ADD_SLETTEREGEL));
    }
}

function* updateSletteregel({ sletteregelId, betingelse }) {
    try {
        yield call(apiPut, SLETTEREGLER, { sletteregelId, betingelse });
        yield put(fetchSlettereglerRequest());
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.UPDATE_SLETTEREGEL));
    }
}

function* deleteSletteregel({ sletteregelId }) {
    try {
        yield call(httpDelete, DELETE_SLETTEREGEL(sletteregelId));
        yield put(fetchSlettereglerRequest());
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.DELETE_SLETTEREGEL));
    }
}

function* runSletteregel({ sletteregelId, fromDate }) {
    try {
        yield call(post, RUN_SLETTEREGEL(sletteregelId) + queryParams({ fraDato: fromDate.toISOString().split('T', 1)[0] }));
        yield put(runRegelSuccess());
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.RUN_SLETTEREGEL));
    }
}

function* checkRunRegelEffect({ sletteregelId, fromDate }) {
    try {
        const response = yield call(get, CHECK_SLETTEREGEL(sletteregelId) + queryParams({ fraDato: fromDate.toISOString().split('T', 1)[0] }));
        yield put(checkRunRegelEffectSuccess(response.data));
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.CHECK_RUN_SLETTEREGEL));
    }
}

export default [
    takeLatest(FETCH_VALIDERINGSBEGRUNNELSER_REQUEST, fetchValideringsbegrunnelser),
    takeLatest(SAVE_VALIDERINGSBEGRUNNELSE_REQUEST, saveValideringsbegrunnelse),
    takeLatest(UPDATE_VALIDERINGSBEGRUNNELSE_REQUEST, updateValideringsbegrunnelse),
    takeLatest(APPROVE_HFM_EXPORT_REQUEST, approveHFMExport),
    takeLatest(CREATE_HFM_EXPORT_REQUEST, createHfmExport),
    takeLatest(DOWNLOAD_HFM_EXPORT_REQUEST, downloadHfmExport),
    takeLatest(FETCH_LATEST_HFM_EXPORT_REQUEST, fetchLatestHfmExport),
    takeLatest(POLL_LATEST_HFM_EXPORT, fetchLatestHfmExport),
    takeLatest(FETCH_SLETTEREGLER_REQUEST, fetchSletteregler),
    takeLatest(GJENOPPRETT_POSTER_REQUEST, gjenopprettSletteregel),
    takeLatest(ADD_SLETTEREGEL_REQUEST, addSletteregel),
    takeLatest(UPDATE_SLETTEREGEL_REQUEST, updateSletteregel),
    takeLatest(DELETE_SLETTEREGEL_REQUEST, deleteSletteregel),
    takeLatest(RUN_REGEL_REQUEST, runSletteregel),
    takeLatest(CHECK_RUN_REGEL_EFFECT_REQUEST, checkRunRegelEffect),
];
