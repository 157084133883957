import { call, takeLatest, put } from 'redux-saga/effects';
import fileDownload from 'js-file-download';
import { get, getFile, post } from '../../config/apiConfig';
import { GET_BUNKE,
    IKKE_ELIMINERTE_BUNKER,
    ELIMINER_POSTER,
    FEILBUNKER,
    SEND_TIL_FEILTABELL,
    FJERN_FRA_FEILTABELL,
    TOGGLE_SEND_VARSEL,
    OPPHEV_BUNKE,
    POST_SEND_VARSEL, EXPORT_BUNKER, BUNKE_HISTORIKK } from '../../constants/endPoints';
import { FETCH_BUNKE_REQUEST,
    FETCH_IKKE_ELIMINERTE_BUNKER_REQUEST,
    fetchBunkeSuccess,
    fetchIkkeEliminerteBunkerSuccess,
    eliminerPosterSuccess,
    ELIMINER_POSTER_REQUEST,
    fetchFeilBunkerSuccess,
    fetchFeilBunkerFailure,
    FETCH_FEILBUNKER_REQUEST,
    sendToFeiltabellSuccess,
    sendToFeiltabellFailure,
    SEND_TO_FEILTABELL_REQUEST,
    fetchFeilBunkerRequest,
    REMOVE_FROM_FEILTABELL_REQUEST,
    removeFromFeiltabellSuccess,
    TOGGLE_SEND_VARSEL_REQUEST,
    fetchIkkeEliminerteBunkerRequest,
    POST_OPPHEV_BUNKE_ELIMINERING_SUCCESS,
    POST_OPPHEV_BUNKE_ELIMINERING_REQUEST,
    postOpphevBunkeElimineringSuccess,
    postOpphevBunkeElimineringFailed,
    SEND_VARSEL,
    eliminerPosterFailure,
    fetchExportBunkerSuccess,
    EXPORT_BUNKER_REQUEST,
    fetchExportBunkerFailed, BUNKE_HISTORIKK_REQUEST, fetchBunkeHistorikkSuccess } from '../actions/manuellElimineringActions';
import { displayErrorMessage } from '../actions/errorActions';
import NOB from '../../constants/languageStrings';

function* fetchIkkeEliminerteBunker() {
    try {
        const response = yield call(get, IKKE_ELIMINERTE_BUNKER);
        yield put(fetchIkkeEliminerteBunkerSuccess(response.data));
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.FETCH_IKKE_ELIMINERTE_BUNKER_FAILED));
    }
}

function* fetchBunke({ bunkeId }) {
    try {
        const response = yield call(get, GET_BUNKE(bunkeId));
        yield put(fetchBunkeSuccess(response.data));
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.FETCH_BUNKE_ERROR));
    }
}

function* eliminerPoster({ poster }) {
    try {
        yield call(post, ELIMINER_POSTER, poster);
        yield put(eliminerPosterSuccess());
    } catch (error) {
        yield put(eliminerPosterFailure());
        yield put(displayErrorMessage(NOB.ERROR.ELIMINER_POSTER_ERROR));
    }
}

function* fetchFeilbunker() {
    try {
        const response = yield call(get, FEILBUNKER);
        yield put(fetchFeilBunkerSuccess(response.data));
    } catch (error) {
        yield put(fetchFeilBunkerFailure());
        yield put(displayErrorMessage(NOB.ERROR.FETCH_BUNKE_ERROR));
    }
}

function* sendToFeiltabell({ bunkeId, comment }) {
    try {
        yield call(post, SEND_TIL_FEILTABELL(bunkeId), comment, { headers: { 'Content-Type': 'text/plain' } });
        yield put(sendToFeiltabellSuccess(bunkeId));
        yield put(fetchFeilBunkerRequest());
    } catch (error) {
        yield put(sendToFeiltabellFailure());
        yield put(displayErrorMessage(NOB.ERROR.FETCH_BUNKE_ERROR));
    }
}

function* fjernFraFeiltabell({ bunkeId }) {
    try {
        yield call(post, FJERN_FRA_FEILTABELL(bunkeId));
        yield put(removeFromFeiltabellSuccess());
        yield put(fetchFeilBunkerRequest());
        yield put(fetchIkkeEliminerteBunkerRequest());
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.FETCH_BUNKE_ERROR));
    }
}

function* toggleSendVarsel({ bunkeId, sendVarselValue }) {
    try {
        yield call(post, TOGGLE_SEND_VARSEL(bunkeId, sendVarselValue));
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.FETCH_BUNKE_ERROR));
    }
}

function* sendVarsel() {
    try {
        yield call(post, POST_SEND_VARSEL);
        yield put(fetchFeilBunkerRequest());
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.SEND_VARSEL_ERROR));
    }
}

function* opphevBunke({ bunkeId }) {
    try {
        yield call(post, OPPHEV_BUNKE(bunkeId));
        yield put(postOpphevBunkeElimineringSuccess());
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.OPPHEV_BUNKE_FAILED));
        yield put(postOpphevBunkeElimineringFailed());
    }
}

function* exportBunker() {
    try {
        const response = yield call(getFile, EXPORT_BUNKER);
        fileDownload(response.data, 'datagrunnlag.xlsx');
        yield put(fetchExportBunkerSuccess());
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.EXPORT_BUNKER_ERROR));
        yield put(fetchExportBunkerFailed());
    }
}

function* fetchBunkeHistorikk() {
    try {
        const response = yield call(get, BUNKE_HISTORIKK);
        yield put(fetchBunkeHistorikkSuccess(response.data));
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.BUNKE_HISTORIKK_ERROR));
    }
}

export default [
    takeLatest(FETCH_IKKE_ELIMINERTE_BUNKER_REQUEST, fetchIkkeEliminerteBunker),
    takeLatest(FETCH_BUNKE_REQUEST, fetchBunke),
    takeLatest(ELIMINER_POSTER_REQUEST, eliminerPoster),
    takeLatest(FETCH_FEILBUNKER_REQUEST, fetchFeilbunker),
    takeLatest(SEND_TO_FEILTABELL_REQUEST, sendToFeiltabell),
    takeLatest(REMOVE_FROM_FEILTABELL_REQUEST, fjernFraFeiltabell),
    takeLatest(TOGGLE_SEND_VARSEL_REQUEST, toggleSendVarsel),
    takeLatest(SEND_VARSEL, sendVarsel),
    takeLatest(POST_OPPHEV_BUNKE_ELIMINERING_SUCCESS, fetchFeilbunker),
    takeLatest(POST_OPPHEV_BUNKE_ELIMINERING_REQUEST, opphevBunke),
    takeLatest(EXPORT_BUNKER_REQUEST, exportBunker),
    takeLatest(BUNKE_HISTORIKK_REQUEST, fetchBunkeHistorikk),
];
