import axios from 'axios';
import { OKTA_TOKEN_STORAGE } from '../constants/appConstants';

export const createApiInstance = () => {
    const instance = axios.create();
    instance.defaults.headers.Accept = 'application/json';
};

const setToken = () => {
    if (localStorage.getItem(OKTA_TOKEN_STORAGE) != null) {
        const token = JSON.parse(localStorage.getItem('okta-token-storage')).accessToken.accessToken;
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    }
};

export const removeDefaultHeaders = () => {
    delete axios.defaults.headers.Authorization;
};

export const queryParams = paramObject => {
    const keys = Object.keys(paramObject);
    const params = keys.filter(key => paramObject[key] !== null && paramObject[key].toString().length > 0).map(key => {
        const values = paramObject[key];
        return `${key}=${Array.isArray(values)
            ? `${values.map((value, i) => {
                const valString = value.toString();
                return values.length - 1 === i ? valString : `${valString}&${key}=`;
            }).join('')}` : `${values.toString()}`}`;
    }).join('&');
    return `?${params}`;
};

export const queryParamsFromList = (key, values) => {
    const params = values.map(value => `${key}=${value}`).join('&');
    return `?${params}`;
};

export const get = (url, params) => {
    setToken();
    return axios.get(url, params);
};

export const getFile = (url, params) => {
    setToken();
    return axios.get(url, { ...params, responseType: 'blob' });
};

export const put = (url, body, config) => {
    setToken();
    return axios.put(url, body, config);
};

export const post = (url, body, config) => {
    setToken();
    return axios.post(url, body, config);
};

export const httpDelete = (url, body) => {
    setToken();
    return axios.delete(url, body);
};
