import { NAV_TO_MANUELL_AVSTEMMING_FAILED, NAV_TO_MANUELL_AVSTEMMING_REQUEST } from '../actions/dashboardAction';
import { AVSTEMMING_POST_SUCCESS,
    FILTER_CHANGED,
    SELSKAP_LIST_FETCH_REQUEST,
    SELSKAP_LIST_FETCH_SUCCESS,
    PERIODER_FILTER_FETCH_FAILED,
    PERIODER_FILTER_FETCH_REQUEST,
    PERIODER_FILTER_FETCH_SUCCESS,
    SELSKAP_FAVOURITE_LIST_FETCH_REQUEST,
    POST_HISTORY_FETCH_REQUEST,
    POST_HISTORY_FETCH_SUCCESS,
    FETCH_SELSKAPER_FOR_MOTPART_SUCCESS,
    POSTKOMMENTARER_FETCH_REQUEST,
    POSTKOMMENTARER_FETCH_SUCCESS,
    POST_OPPHEV_BUNKE_REQUEST,
    POST_OPPHEV_BUNKE_FAILED,
    POST_OPPHEV_BUNKE_SUCCESS,
    AVSTEMMING_CONFIRMATION_VISIBLE,
    AVSTEMMING_POST_REQUEST,
    FILTER_RESET,
    AVSTEMMING_INF_SCROLL_FETCH_SUCCESS,
    AVSTEMMING_POST_FAILED,
    AVSTEMMING_INF_SCROLL_FETCH_REQUEST } from '../actions/manuellAvstemmingAction';
import { DISPLAY_ERROR_LIST, DISPLAY_ERROR_MESSAGE } from '../actions/errorActions';

const initialState = {
    errorMessage: '',
    selskapList: [],
    selskapFilterOptions: [],
    motpartFilterOptions: [],
    perioder: [],
    filterOptions: {
        selskapNr: '',
        selskapPostTp: '',
        motpartselskapNr: '',
        motpartselskapType: '',
        avstemt: false,
        periodeFra: '',
        periodeTil: '',
    },
    favouriteCheckboxChecked: false,
    postId: '',
    postkommentarer: [],
    postHistory: [],
    opphevBunkePosting: false,
    opphevBunkeSuccess: false,
    avstemmingConfirmationVisible: false,
    isAvstemmingSuccessful: false,
    regelErrors: [],
    avstemPosterIsFetching: false,
    postHistoryIsFetching: false,
    isFetching: false,
};

export function ManuellAvstemmingReducer(prevState = initialState, action) {
    const mapSelskapData = selskapList => selskapList.map(({ selskapnavn, selskapNr }) => ({
        name: `${selskapNr} - ${selskapnavn}`,
        value: selskapNr,
    }));

    switch (action.type) {
        case AVSTEMMING_INF_SCROLL_FETCH_REQUEST:
            return {
                ...prevState,
                isFetching: true,
            };
        case AVSTEMMING_INF_SCROLL_FETCH_SUCCESS:
            return {
                ...prevState,
                isFetching: false,
            };
        case AVSTEMMING_POST_REQUEST:
            return {
                ...prevState,
                avstemPosterIsFetching: true,
                isAvstemmingSuccessful: false,
            };
        case AVSTEMMING_POST_SUCCESS:
            return {
                ...prevState,
                avstemPosterIsFetching: false,
                isAvstemmingSuccessful: true,
            };
        case AVSTEMMING_POST_FAILED:
            return {
                ...prevState,
                avstemPosterIsFetching: false,
            };
        case FILTER_CHANGED:
            return {
                ...prevState,
                filterOptions: {
                    ...prevState.filterOptions,
                    [action.id]: action.value,
                },
            };
        case FILTER_RESET:
            return {
                ...prevState,
                filterOptions: {
                    ...initialState.filterOptions,
                },
            };
        case SELSKAP_LIST_FETCH_REQUEST:
            return {
                ...prevState,
            };
        case SELSKAP_LIST_FETCH_SUCCESS:
            return {
                ...prevState,
                selskapFilterOptions: mapSelskapData(action.allSelskapList),
            };
        case NAV_TO_MANUELL_AVSTEMMING_REQUEST:
            return {
                ...prevState,
            };

        case NAV_TO_MANUELL_AVSTEMMING_FAILED:
            return {
                ...prevState,
                errorMessage: action.errorMessage,
            };
        case FETCH_SELSKAPER_FOR_MOTPART_SUCCESS:
            return {
                ...prevState,
                motpartFilterOptions: mapSelskapData(action.motpartselskaper),
            };
        case SELSKAP_FAVOURITE_LIST_FETCH_REQUEST:
            return {
                ...prevState,
                favouriteCheckboxChecked: action.checked,
            };
        case POST_HISTORY_FETCH_REQUEST:
            return {
                ...prevState,
                postHistoryIsFetching: true,
            };
        case POST_HISTORY_FETCH_SUCCESS:
            return {
                ...prevState,
                postHistoryIsFetching: false,
                postHistory: action.bunke,
            };
        case POSTKOMMENTARER_FETCH_REQUEST:
            return {
                ...prevState,
                isFetchingKommentarer: true,
            };
        case POSTKOMMENTARER_FETCH_SUCCESS:
            return {
                ...prevState,
                postkommentarer: action.postkommentarer,
            };
        case POST_OPPHEV_BUNKE_REQUEST:
            return {
                ...prevState,
                opphevBunkePosting: true,
                isAvstemmingSuccessful: false,
            };
        case POST_OPPHEV_BUNKE_SUCCESS:
            return {
                ...prevState,
                opphevBunkeSuccess: true,
                opphevBunkePosting: false,
                isAvstemmingSuccessful: true,
            };
        case POST_OPPHEV_BUNKE_FAILED:
            return {
                ...prevState,
                opphevBunkeSuccess: false,
                opphevBunkePosting: false,
            };
        case AVSTEMMING_CONFIRMATION_VISIBLE:
            return {
                ...prevState,
                avstemmingConfirmationVisible: action.isVisible,
                avstemPosterIsFetching: action.isVisible ? prevState.avstemPosterIsFetching : false,
                regelErrors: action.isVisible ? action.regelErrors : [],
            };
        case DISPLAY_ERROR_LIST:
            return {
                ...prevState,
                avstemPosterIsFetching: false,
            };
        case DISPLAY_ERROR_MESSAGE:
            return {
                ...prevState,
                isFetching: false,
            };
        default:
            return prevState;
    }
}


export function PeriodeFilterReducer(prevState = initialState, action) {
    switch (action.type) {
        case PERIODER_FILTER_FETCH_REQUEST:
            return {
                ...prevState,
            };
        case PERIODER_FILTER_FETCH_SUCCESS:
            return {
                ...prevState,
                perioder: action.perioder.map(({ periodeId }) => ({
                    name: periodeId.toString(),
                    value: periodeId,
                })),
            };
        case PERIODER_FILTER_FETCH_FAILED:
            return {
                ...prevState,
                errorMessage: action.errorMessage,
            };
        default:
            return prevState;
    }
}
