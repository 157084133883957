import { FETCH_AUTOMATISK_ELIMINERING_LOGG_SUCCESS } from '../actions/automatiskElimineringActions';

const initialState = {
    logg: [],
};

export default function AutomatiskElimineringReducer(prevState = initialState, action) {
    switch (action.type) {
        case FETCH_AUTOMATISK_ELIMINERING_LOGG_SUCCESS:
            return {
                ...prevState,
                logg: action.logg,
            };
        default:
            return prevState;
    }
}
