export const FETCH_AUTOMATISK_ELIMINERING_LOGG_REQUEST = 'FETCH_AUTOMATISK_ELIMINERING_LOGG_REQUEST';
export const FETCH_AUTOMATISK_ELIMINERING_LOGG_SUCCESS = 'FETCH_AUTOMATISK_ELIMINERING_LOGG_SUCCESS';

export const fetchAutomatiskElimineringLogg = () => ({
    type: FETCH_AUTOMATISK_ELIMINERING_LOGG_REQUEST,
});

export const fetchAutomatiskElimineringLoggSuccess = logg => ({
    type: FETCH_AUTOMATISK_ELIMINERING_LOGG_SUCCESS,
    logg,
});
