import PAGES from '../constants/pages';
import NOB from '../constants/languageStrings';

const { MENU } = NOB;

export function getPageFromPath(path) {
    return Object.values(PAGES).reduce((page, currentpage) => path.startsWith(currentpage) ? currentpage : page);
}

export function getComponentNameFromPath(path) {
    const page = getPageFromPath(path);

    switch (page) {
        case PAGES.DASHBOARD:
            return MENU.DASHBOARD;
        case PAGES.MANUELL_AVSTEMMING:
            return MENU.MANUELL_AVSTEMMING;
        case PAGES.AUTOMATISK_AVSTEMMING:
            return MENU.AUTOMATISK_AVSTEMMING;
        default:
            return '';
    }
}
