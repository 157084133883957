export const CLOSE_ERROR_MESSAGE = 'CLOSE_ERROR_MESSAGE';
export const DISPLAY_ERROR_MESSAGE = 'DISPLAY_ERROR_MESSAGE';
export const DISPLAY_ERROR_LIST = 'DISPLAY_ERROR_LIST';

// Action-creators
export const closeErrorMessage = () => ({
    type: CLOSE_ERROR_MESSAGE,
});

export const displayErrorMessage = errorMessage => ({
    type: DISPLAY_ERROR_MESSAGE,
    errorMessage,
});

export const displayErrorList = errors => ({
    type: DISPLAY_ERROR_LIST,
    errors,
});
