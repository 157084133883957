import { all } from 'redux-saga/effects';
import dashboardSaga from './dashboardSaga';
import manuellAvstemmingSaga from './manuellAvstemmingSaga';
import regelSaga from './regelSaga';
import adminSaga from './adminSaga';
import automatiskAvstemmingSaga from './automatiskAvstemmingSaga';
import kontoplanSaga from './kontoplanSaga';
import manuellElimineringSaga from './manuellElimineringSaga';
import elimineringsSaga from './elimineringOversiktSaga';
import automatiskElimineringSaga from './automatiskElimineringSaga';

export default function* rootSaga() {
    yield all([
        ...dashboardSaga,
        ...manuellAvstemmingSaga,
        ...regelSaga,
        ...adminSaga,
        ...automatiskAvstemmingSaga,
        ...kontoplanSaga,
        ...elimineringsSaga,
        ...manuellElimineringSaga,
        ...automatiskElimineringSaga,
    ]);
}
