import { LOGIN_USER_REQUEST, LOGIN_USER_SUCCESS, LOGIN_USER_ERROR, VERIFY_TOKEN_REQUEST } from '../actions/authenticationActions';

const intitialState = {
    isAuthenticated: false,
    isAuthenticating: false,
    isFetching: false,
    errorMessage: '',
};

function AuthenticationReducer(prevState = intitialState, action) {
    switch (action.type) {
        case LOGIN_USER_REQUEST:
            return {
                ...intitialState,
                isAuthenticating: true,
            };
        case LOGIN_USER_SUCCESS:
            return {
                ...intitialState,
                isAuthenticating: false,
                isAuthenticated: true,
            };
        case LOGIN_USER_ERROR:
            return {
                ...intitialState,
                errorMessage: action.errorMessage,
            };
        case VERIFY_TOKEN_REQUEST:
            return {
                ...intitialState,
                isFetching: true,
            };
        default:
            return prevState;
    }
}

export default AuthenticationReducer;
