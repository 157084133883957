import React from 'react';
import { LicenseManager } from 'ag-grid-enterprise';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { createRoot } from 'react-dom/client';
import configureStore from './redux/store';
import licenseKey from './config/agGridConfig';
import App from './components/app/app';
import './styles/index.scss';
import { history } from './history';


const store = configureStore({}, history);
LicenseManager.setLicenseKey(licenseKey);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <App />
        </ConnectedRouter>
    </Provider>,
);
