import { takeLatest, put, call } from '@redux-saga/core/effects';
import { select } from 'redux-saga/effects';
import fileDownload from 'js-file-download';
import { EKSPORTER_DATAGRUNNLAG_REQUEST, eksporterDatagrunnlagFailed, eksporterDatagrunnlagSuccess,
    ELIMINERING_OVERSIKT_REQUEST, elimineringOpphevingSuccess, OPPHEV_ELIMINERING_BUNKE_REQUEST,
    fetchPosterSuccess, OPPHEV_AVSTEMMING_BUNKER_REQUEST, opphevAvstemmingBunkerSuccess, opphevAvstemmingBunkerFailed } from '../actions/elimineringsAction';
import { get, getFile, post, queryParams } from '../../config/apiConfig';
import { EKSPORTER_DATAGRUNNLAG,
    ELIMINERING_OVERSIKT_POST,
    OPPHEV_BUNKE_ELIMINERING,
    OPPHEV_BUNKER } from '../../constants/endPoints';
import { displayErrorMessage } from '../actions/errorActions';
import NOB from '../../constants/languageStrings';
import { PAGE_SIZE } from '../../constants/appConstants';
import { fetchBunkeHistorikkRequest } from '../actions/manuellElimineringActions';

function* fetchElimineringsOveriktInfinateScrolling({ params }) {
    try {
        const filters = yield select(state => state.eliminering.filterOptions);
        const PAGE_NUMBER = Math.floor(params.request.endRow / PAGE_SIZE) - 1;
        const { sortModel } = params.request;

        const response = yield call(get, ELIMINERING_OVERSIKT_POST + queryParams({
            ...filters,
            avstemt: true,
            pageNumber: PAGE_NUMBER,
            pageSize: PAGE_SIZE,
            sortField: sortModel.length > 0 ? sortModel[0].colId : '',
            sortDirection: sortModel.length > 0 ? sortModel[0].sort.toUpperCase() : '',
        }));

        const lastRowIndex = response.data.value.length === PAGE_SIZE ? null : PAGE_NUMBER * PAGE_SIZE + response.data.value.length;

        params.success({
            rowData: response.data.value.map(data => ({
                ...data,
                isMotpart: data.selskapNr !== filters.selskapNr,
                avstemmingTp: data.avstemtRegelId ? NOB.OVERSIKT.AUTOMATIC : NOB.OVERSIKT.MANUAL,
                eliminert: data.eliminert ? NOB.OVERSIKT.YES : NOB.OVERSIKT.NO,
                ompostering: data.ompostering ? NOB.OVERSIKT.YES : NOB.OVERSIKT.NO,
            })),
            rowCount: lastRowIndex,
        });
        yield put(fetchPosterSuccess(response.data.key));
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.POST_LIST_FAILED));
        params.fail();
    }
}

function* opphevBunke({ bunkeId }) {
    try {
        yield call(post, OPPHEV_BUNKE_ELIMINERING(bunkeId));
        yield put(fetchBunkeHistorikkRequest());
        yield put(elimineringOpphevingSuccess());
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.OPPHEV_BUNKE_FAILED));
    }
}

function* eksporterDatagrunnlag() {
    try {
        const filters = yield select(state => state.eliminering.filterOptions);
        const { sortModel } = yield select(state => state.eliminering.sortModel);
        const response = yield call(getFile, EKSPORTER_DATAGRUNNLAG + queryParams({ ...filters,
            sortField: sortModel && sortModel.sortField ? sortModel.sortField : '',
            sortDirection: sortModel && sortModel.sortDirection ? sortModel.sortDirection.toUpperCase() : '' }));

        fileDownload(response.data, 'datagrunnlag.xlsx');
        yield put(eksporterDatagrunnlagSuccess());
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.CREATE_DATAGRUNNLAG));
        yield put(eksporterDatagrunnlagFailed());
    }
}

function* opphevAvstemmingBunker() {
    try {
        const filters = yield select(state => state.eliminering.filterOptions);
        yield call(post, OPPHEV_BUNKER + queryParams(filters));
        yield put(opphevAvstemmingBunkerSuccess());
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.OPPHEV_AVSTEMMING_BUNKER));
        yield put(opphevAvstemmingBunkerFailed());
    }
}

export default [
    takeLatest(ELIMINERING_OVERSIKT_REQUEST, fetchElimineringsOveriktInfinateScrolling),
    takeLatest(OPPHEV_ELIMINERING_BUNKE_REQUEST, opphevBunke),
    takeLatest(EKSPORTER_DATAGRUNNLAG_REQUEST, eksporterDatagrunnlag),
    takeLatest(OPPHEV_AVSTEMMING_BUNKER_REQUEST, opphevAvstemmingBunker),
];
