const PAGES = {
    AUTHENTICATION: '/login',
    DASHBOARD: '/',
    MANUELL_AVSTEMMING: '/manuell-avstemming',
    REGLER: '/regler',
    KONTOPLAN: '/kontoplan',
    ELIMINERING_OVERSIKT: '/eliminering-oversikt',
    LOGOUT: '/logout',
    ADMIN: '/admin',
    LOGG: '/logg',
    FEILTABELL: '/feiltabell',
    MANUELL_ELIMINERING: '/manuell-eliminering',
};

export default PAGES;
