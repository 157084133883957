/* eslint-disable no-unused-vars */
import { call, put, takeLatest } from 'redux-saga/effects';
import { get, httpDelete, post } from '../../config/apiConfig';
import { displayErrorMessage } from '../actions/errorActions';
import { fetchSelskapListSuccess,
    fetchSelskapInfoSuccess,
    fetchPerioderSuccess,
    fetchSelskapListFailed,
    fetchSelskapInfoFailed,
    fetchPerioderFailed,
    SELSKAP_FETCH_REQUEST,
    SELSKAP_INFO_FETCH_REQUEST,
    PERIODER_FETCH_REQUEST,
    NAV_TO_MANUELL_AVSTEMMING_REQUEST,
    navToManuellAvstemmingFailed,
    TOGGLE_FAVOURITE_SELSKAP_REQUEST,
    favouriteToggledSuccess } from '../actions/dashboardAction';
import NOB from '../../constants/languageStrings';
import { GET_SELSKAP, GET_SELSKAP_INFO, GET_PERIODER, CHANGE_FAVOURITE, GET_ALL_PERIODER } from '../../constants/endPoints';
import { history } from '../../history';
import { filterChanged, selskapFilterChanged } from '../actions/manuellAvstemmingAction';

function* fetchSelskapList({ periode }) {
    try {
        const response = yield call(get, GET_SELSKAP(periode));
        yield put(fetchSelskapListSuccess(response.data));
    } catch (error) {
        yield put(fetchSelskapListFailed(error.message));
        yield put(displayErrorMessage(NOB.ERROR.SELSKAP_LIST_FAILED));
    }
}

function* fetchSelskapInfo({ periode, selskapNr }) {
    try {
        const response = yield call(get, GET_SELSKAP_INFO(periode, selskapNr));
        yield put(fetchSelskapInfoSuccess(response.data));
    } catch (error) {
        yield put(fetchSelskapInfoFailed(error.message));
        yield put(displayErrorMessage(NOB.ERROR.SELSKAP_INFO_FAILED));
    }
}

function* fetchPerioder({ periodestatus }) {
    try {
        let response;
        if (periodestatus) {
            response = yield call(get, GET_PERIODER(periodestatus));
        } else {
            response = yield call(get, GET_ALL_PERIODER);
        }
        yield put(fetchPerioderSuccess(response.data));
    } catch (error) {
        yield put(fetchPerioderFailed(error.message));
        yield put(displayErrorMessage(NOB.ERROR.PERIODER_FAILED));
    }
}

function* navToManuellAvstemming() {
    try {
        history.push('/manuell-avstemming');
    } catch (error) {
        yield put(navToManuellAvstemmingFailed(error));
    }
}

function* favouriteToggled({ selskap }) {
    try {
        if (!selskap.favoritt) {
            yield call(post, CHANGE_FAVOURITE(selskap.selskapNr));
        } else {
            yield call(httpDelete, CHANGE_FAVOURITE(selskap.selskapNr));
        }
        yield put(favouriteToggledSuccess(selskap.selskapNr, !selskap.favoritt));
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.TOGGLE_FAVOURITE_FAILED));
    }
}

export { fetchPerioder };

export default [
    takeLatest(SELSKAP_FETCH_REQUEST, fetchSelskapList),
    takeLatest(SELSKAP_INFO_FETCH_REQUEST, fetchSelskapInfo),
    takeLatest(NAV_TO_MANUELL_AVSTEMMING_REQUEST, navToManuellAvstemming),
    takeLatest(PERIODER_FETCH_REQUEST, fetchPerioder),
    takeLatest(TOGGLE_FAVOURITE_SELSKAP_REQUEST, favouriteToggled),
];
