export const FETCH_IG_KONTOER_REQUEST = 'FETCH_IG_KONTOER_REQUEST';
export const FETCH_IG_KONTOER_SUCCESS = 'FETCH_IG_KONTOER_SUCCESS';

export const ADD_KONSERNKONTO_TO_IG_KONTO_REQUEST = 'ADD_KONSERNKONTO_TO_IG_KONTO_REQUEST';

export const ADD_IG_KONTO_REQUEST = 'ADD_IG_KONTO_REQUEST';

export const FETCH_KONSERNKONTOER_REQUEST = 'FETCH_KONSERNKONTOER_REQUEST';
export const FETCH_KONSERNKONTOER_SUCCESS = 'FETCH_KONSERNKONTOER_SUCCESS';

export const REMOVE_KONSERNKONTO_FROM_IG_KONTO_REQUEST = 'REMOVE_KONSERNKONTO_FROM_IG_KONTO_REQUEST';

export const UPDATE_EKSTERN_MOTKONTO_REQUEST = 'UPDATE_EKSTERN_MOTKONTO_REQUEST';

export const TOGGLE_ELIMINERES_REQUEST = 'TOGGLE_ELIMINERES_REQUEST';

export const TOGGLE_MANUELL_BEHANDLING_REQUEST = 'TOGGLE_MANUELL_BEHANDLING_REQUEST';

export const UPDATE_KONTOKOMBINAJSON = 'UPDATE_KONTOKOMBINAJSON';

export const FETCH_KONTOKOMBINASJON_REQUEST = 'FETCH_KONTOKOMBINASJON_REQUEST';
export const FETCH_KONTOKOMBINASJON_SUCCESS = 'FETCH_KONTOKOMBINASJON_SUCCESS';

export const DELETE_KONTOKOMBINASJON_REQUEST = 'DELETE_KONTOKOMBINASJON_REQUEST';

export const TOGGLE_OMPOSTERING_REQUEST = 'TOGGLE_OMPOSTERING_REQUEST';

export const fetchIgKontoerRequest = () => ({
    type: FETCH_IG_KONTOER_REQUEST,
});

export const fetchIgKontoerSuccess = igKontoer => ({
    type: FETCH_IG_KONTOER_SUCCESS,
    igKontoer,
});

export const addKonsernkontoToIgKontoRequest = (igKontoId, konsernkontoId) => ({
    type: ADD_KONSERNKONTO_TO_IG_KONTO_REQUEST,
    igKontoId,
    konsernkontoId,
});

export const addIgKontoRequest = igKonto => ({
    type: ADD_IG_KONTO_REQUEST,
    igKonto,
});

export const fetchKonsernkontoerRequest = () => ({
    type: FETCH_KONSERNKONTOER_REQUEST,
});

export const fetchKonsernkontoerSuccess = konsernkontoer => ({
    type: FETCH_KONSERNKONTOER_SUCCESS,
    konsernkontoer,
});

export const removeKonserkontoFromIgKontoRequest = konsernkontoId => ({
    type: REMOVE_KONSERNKONTO_FROM_IG_KONTO_REQUEST,
    konsernkontoId,
});

export const updateEksternMotkontoRequest = (igKontoId, eksternMotkonto) => ({
    type: UPDATE_EKSTERN_MOTKONTO_REQUEST,
    igKontoId,
    eksternMotkonto,
});

export const toggleElimineres = (elimineringKontokombinasjon, checked) => ({
    type: TOGGLE_ELIMINERES_REQUEST,
    elimineringKontokombinasjon: [{ ...elimineringKontokombinasjon, elimineres: checked }],
});

export const toggleManualbehandling = (elimineringKontokombinasjon, checked) => ({
    type: TOGGLE_MANUELL_BEHANDLING_REQUEST,
    elimineringKontokombinasjon: [{ ...elimineringKontokombinasjon, behandlesManuelt: checked }],
});

export const updateKontokombinasjon = elimineringKontokombinasjon => ({
    type: UPDATE_KONTOKOMBINAJSON,
    elimineringKontokombinasjon: [elimineringKontokombinasjon],
});

export const updateKontokombinasjonPriority = elimineringKontokombinasjonList => ({
    type: UPDATE_KONTOKOMBINAJSON,
    elimineringKontokombinasjon: elimineringKontokombinasjonList,
});

export const fetchKontokombinasjonRequest = () => ({
    type: FETCH_KONTOKOMBINASJON_REQUEST,
});

export const fetchKontokombinasjonSuccess = kontokombinasjon => ({
    type: FETCH_KONTOKOMBINASJON_SUCCESS,
    kontokombinasjon,
});

export const deleteKontokombinasjonRequest = kontokombinasjonId => ({
    type: DELETE_KONTOKOMBINASJON_REQUEST,
    kontokombinasjonId,
});
