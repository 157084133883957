import React, { useState } from 'react';
import { Button } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { AccountTree, Dashboard, DataUsage, ExitToApp, Gavel, SupervisorAccount, Autorenew, CheckCircle, FormatListBulleted, ErrorOutline } from '@mui/icons-material';
import PropTypes from 'prop-types';
import PAGES from '../../../constants/pages';
import NOB from '../../../constants/languageStrings';
import { getPageFromPath } from '../../../utils/pathUtil';
import './mainMenu.scss';
import useAuthentication from '../../../hooks/useAuthentication';
import USER_GROUPS from '../../../constants/userGroups';

function MainMenu({ oktaAuth }) {
    const userInfo = useAuthentication();

    const [open, setOpen] = useState(true);
    const cssClass = `main-menu ${open ? '' : 'closed'}`;
    const oktaAuthState = oktaAuth.authStateManager.getAuthState();
    return (
        <>
            {userInfo === null && oktaAuthState.isPending && (
                <div className="not-signed-in">
                    {NOB.MENU.NO_ACCESS}
                </div>
            )}
            {userInfo !== null
          && (
              <div className={cssClass}>
                  <div className="menu-title-container">
                      <Button
                          className="menu-title"
                          onClick={() => setOpen(!open)}
                          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
                      >
                          <span className="app-name">{NOB.APPNAME.FIRST}</span>
                          <span className="app-name">{NOB.APPNAME.SECOND}</span>
                          <span className="app-name">{NOB.APPNAME.THIRD}</span>
                      </Button>
                  </div>
                  <>
                      <NavLink
                          className="menu-item"
                          to={PAGES.DASHBOARD}
                          isActive={(match, { pathname }) => getPageFromPath(pathname) === PAGES.DASHBOARD}
                      >
                          <Dashboard />
                          <span>
                              {NOB.MENU.DASHBOARD}
                          </span>
                      </NavLink>

                      <span className="separator" />

                      <NavLink
                          className="menu-item"
                          to={PAGES.MANUELL_AVSTEMMING}
                          isActive={(match, { pathname }) => getPageFromPath(pathname) === PAGES.MANUELL_AVSTEMMING}
                      >
                          <DataUsage />
                          <span>
                              {NOB.MENU.MANUELL_AVSTEMMING}
                          </span>
                      </NavLink>
                  </>
                  {userInfo.groups.includes(USER_GROUPS.ELIMINERING)
                && (
                    <>
                        <NavLink
                            className="menu-item"
                            to={PAGES.MANUELL_ELIMINERING}
                            isActive={(match, { pathname }) => getPageFromPath(pathname) === PAGES.MANUELL_ELIMINERING}
                        >
                            <CheckCircle />
                            {NOB.MENU.MANUELL_ELIMINERING}
                        </NavLink>

                        <NavLink
                            className="menu-item"
                            to={PAGES.ELIMINERING_OVERSIKT}
                            isActive={(match, { pathname }) => getPageFromPath(pathname) === PAGES.ELIMINERING_OVERSIKT}
                        >
                            <FormatListBulleted />
                            {NOB.MENU.ELIMINERING_OVERSIKT}
                        </NavLink>
                        <span className="separator" />
                    </>
                )}
                  <NavLink
                      className="menu-item"
                      to={PAGES.FEILTABELL}
                      isActive={(match, { pathname }) => getPageFromPath(pathname) === PAGES.FEILTABELL}
                  >
                      <ErrorOutline />
                      <span>
                          {NOB.MENU.FEILTABELL}
                      </span>
                  </NavLink>

                  {userInfo.groups.includes(USER_GROUPS.ADMIN)
                && (
                    <>

                        <NavLink
                            className="menu-item"
                            to={PAGES.LOGG}
                            isActive={(match, { pathname }) => getPageFromPath(pathname) === PAGES.LOGG}
                        >
                            <Autorenew />
                            <span>
                                {NOB.MENU.LOGG}
                            </span>
                        </NavLink>

                        <NavLink
                            className="menu-item"
                            to={PAGES.REGLER}
                            isActive={(match, { pathname }) => getPageFromPath(pathname) === PAGES.REGLER}
                        >
                            <Gavel />
                            <span>
                                {NOB.MENU.REGLER}
                            </span>
                        </NavLink>

                        <NavLink
                            className="menu-item"
                            to={PAGES.KONTOPLAN}
                            isActive={(match, { pathname }) => getPageFromPath(pathname) === PAGES.KONTOPLAN}
                        >
                            <AccountTree />
                            <span>
                                {NOB.MENU.KONTOPLAN}
                            </span>
                        </NavLink>
                        <NavLink
                            className="menu-item"
                            to={PAGES.ADMIN}
                            isActive={(match, { pathname }) => getPageFromPath(pathname) === PAGES.ADMIN}
                        >
                            <SupervisorAccount />
                            <span>
                                {NOB.MENU.ADMIN}
                            </span>
                        </NavLink>
                    </>
                )}

                  <button
                      className="menu-item logout"
                      onClick={() => oktaAuth.signOut()}
                  >
                      <ExitToApp />
                      <span>
                          {NOB.MENU.LOGOUT}
                      </span>
                  </button>
              </div>
          )}
        </>
    );
}

MainMenu.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    oktaAuth: PropTypes.objectOf(PropTypes.any).isRequired,
};


export default MainMenu;
