
export default function firstDateIsLater(dateString1, dateString2) {
    if (dateString1 && !dateString2) return true;
    if (!dateString1 && dateString2) return false;

    const date1 = new Date(dateString1);
    const date2 = new Date(dateString2);

    return date1.getTime() > date2.getTime();
}
