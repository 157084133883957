import { GET_AVSTEMMING_LOGG_SUCCESS } from '../actions/automatiskAvstemmingActions';
import { KJOR_REGEL } from '../actions/regelActions';

const initialState = {
    logg: [],
};

export default function AutomatiskAvstemmingReducer(prevState = initialState, action) {
    switch (action.type) {
        case GET_AVSTEMMING_LOGG_SUCCESS:
            return {
                ...prevState,
                logg: action.logg,
            };
        case KJOR_REGEL:
            return {
                ...prevState,
            };
        default:
            return prevState;
    }
}
