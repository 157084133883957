/* eslint-disable arrow-body-style */
import React from 'react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { useHistory } from 'react-router';
import Routes from './routes';
import { createApiInstance } from '../../config/apiConfig';
import Error from '../../modules/error/error';
import MainMenu from '../menus/mainMenu/mainMenu';
import './app.scss';
import { oktaAuthConfig } from '../../config/oktaConfig';

const oktaAuth = new OktaAuth(oktaAuthConfig.oidc);

function App() {
    createApiInstance();
    const history = useHistory();
    const restoreOriginalUri = async(_oktaAuth, originalUri) => {
        history.replace(toRelativeUrl(originalUri, window.location.origin));
    };

    return (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <div className="app">
                <div className="menu-wrapper">
                    <MainMenu oktaAuth={oktaAuth} />
                    <Error />
                    <div className="menu-wrapper-middle-column" id="resizeable-container">
                        <Routes oktaAuth={oktaAuth} />
                    </div>
                </div>
            </div>
        </Security>
    );
}

export default App;
