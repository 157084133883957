import { EKSPORTER_DATAGRUNNLAG_FAILED,
    EKSPORTER_DATAGRUNNLAG_REQUEST,
    EKSPORTER_DATAGRUNNLAG_SUCCESS,
    FILTER_CHANGED_ELIMINERING,
    SORT_MODEL_CHANGED,
    OPPHEV_ELIMINERING_BUNKE_REQUEST,
    OPPHEV_ELIMINERING_BUNKE_SUCCESS,
    FETCH_POSTER_SUCCESS,
    OPPHEV_AVSTEMMING_BUNKER_REQUEST,
    OPPHEV_AVSTEMMING_BUNKER_FAILED,
    OPPHEV_AVSTEMMING_BUNKER_SUCCESS } from '../actions/elimineringsAction';
import { POST_OPPHEV_BUNKE_ELIMINERING_REQUEST,
    POST_OPPHEV_BUNKE_ELIMINERING_SUCCESS } from '../actions/manuellElimineringActions';
import { elimineringFilters } from '../../constants/filters';

const initialState = {
    filterOptions: {
        [elimineringFilters.SELSKAP_NR]: '',
        [elimineringFilters.MOTPART_NR]: '',
        [elimineringFilters.REGEL_ID]: '',
        [elimineringFilters.KONSERNKONTO_NR_FRA]: '',
        [elimineringFilters.KONSERNKONTO_NR_TIL]: '',
        [elimineringFilters.KONTO_NR_FRA]: '',
        [elimineringFilters.KONTO_NR_TIL]: '',
        [elimineringFilters.POST_TP]: '',
        [elimineringFilters.FAKTURANUMMER]: '',
        [elimineringFilters.HOVEDKILDE_KD]: '',
        [elimineringFilters.AVSTEMMING_TP]: '',
        [elimineringFilters.BELOP_FRA]: '',
        [elimineringFilters.BELOP_TIL]: '',
        [elimineringFilters.BRUTTOBELOP_FRA]: '',
        [elimineringFilters.BRUTTOBELOP_TIL]: '',
        [elimineringFilters.BUNKE_ID]: '',
    },
    opphevEliminering: false,
    sortModel: {
        sortField: null,
        sortDirection: null,
    },
    datagrunnlagIsFetching: false,
    posterIsFetching: false,
    amountOfBunker: 0,
    opphevAvstemmingBunkerIsFetching: false,
};

function ElimineringReducer(prevState = initialState, action) {
    const { type, id, value, sortModel, amountOfBunker } = action;
    switch (type) {
        case FILTER_CHANGED_ELIMINERING:
            return {
                ...prevState,
                filterOptions: {
                    ...prevState.filterOptions,
                    [id]: value,
                },
            };
        case POST_OPPHEV_BUNKE_ELIMINERING_REQUEST:
            return {
                ...prevState,
                opphevBunkePosting: true,
            };
        case POST_OPPHEV_BUNKE_ELIMINERING_SUCCESS:
            return {
                ...prevState,
                opphevBunkeSuccess: true,
                opphevBunkePosting: false,
            };
        case OPPHEV_ELIMINERING_BUNKE_REQUEST:
            return {
                ...prevState,
                opphevEliminering: true,
            };
        case OPPHEV_ELIMINERING_BUNKE_SUCCESS:
            return {
                ...prevState,
                opphevEliminering: false,
            };
        case EKSPORTER_DATAGRUNNLAG_REQUEST:
            return {
                ...prevState,
                datagrunnlagIsFetching: true,
            };
        case EKSPORTER_DATAGRUNNLAG_SUCCESS:
        case EKSPORTER_DATAGRUNNLAG_FAILED:
            return {
                ...prevState,
                datagrunnlagIsFetching: false,
            };
        case SORT_MODEL_CHANGED:
            return {
                ...prevState,
                sortModel: sortModel || null,
            };
        case FETCH_POSTER_SUCCESS:
            return {
                ...prevState,
                amountOfBunker,
            };
        case OPPHEV_AVSTEMMING_BUNKER_REQUEST:
            return {
                ...prevState,
                opphevAvstemmingBunkerIsFetching: true,
            };
        case OPPHEV_AVSTEMMING_BUNKER_SUCCESS:
        case OPPHEV_AVSTEMMING_BUNKER_FAILED:
            return {
                ...prevState,
                opphevAvstemmingBunkerIsFetching: false,
            };
        default:
            return prevState;
    }
}

export default ElimineringReducer;
