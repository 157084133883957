import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './error.scss';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CloseIcon from '@mui/icons-material/Close';
import NOB from '../../constants/languageStrings';
import { CLOSE_ERROR_MESSAGE } from '../../redux/actions/errorActions';

function Error({ visible, message, messageList, dispatchCloseMessage }) {
    const className = `error-box ${visible ? '' : 'hidden'} `;

    return (
        <div className={className}>
            <div className="content">
                <ErrorOutlineIcon className="info" />
                {message && (
                    <span className="message">
                        {message}
                        {NOB.ERROR.DEFAULT_POSTFIX}
                    </span>
                )}
                {messageList && (
                    <ul>
                        {messageList.map(error => <li key={error}>{error}</li>)}
                    </ul>
                )}
                <CloseIcon className="close" onClick={() => dispatchCloseMessage()}>Error</CloseIcon>
            </div>
        </div>
    );
}

Error.propTypes = {
    visible: PropTypes.bool.isRequired,
    message: PropTypes.string,
    messageList: PropTypes.arrayOf(PropTypes.string),
    dispatchCloseMessage: PropTypes.func.isRequired,
};

Error.defaultProps = {
    message: null,
    messageList: [],
};

const mapStateToProps = ({ error }) => ({
    message: error.message,
    visible: error.visible,
    messageList: error.messageList,
});

const mapDispatchToProps = dispatch => ({
    dispatchCloseMessage: () => {
        dispatch({ type: CLOSE_ERROR_MESSAGE });
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Error);
