// Action-types
export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';

export const VERIFY_TOKEN_REQUEST = 'VERIFY_TOKEN_REQUEST';
export const VERIFY_TOKEN_SUCCESS = 'VERIFY_TOKEN_SUCCESS';
export const VERIFY_TOKEN_ERROR = 'VERIFY_TOKEN_ERROR';

// Action-creators
export const loginUserRequest = (credentials, requestedPath) => ({
    type: LOGIN_USER_REQUEST,
    credentials,
    requestedPath,
});

export const loginUserSuccess = userInfo => ({
    type: LOGIN_USER_SUCCESS,
    userInfo,
});

export const loginUserError = errorMessage => ({
    type: LOGIN_USER_ERROR,
    errorMessage,
});

export const verifyTokenRequest = token => ({
    type: VERIFY_TOKEN_REQUEST,
    token,
});
