export const ELIMINERING_OVERSIKT_REQUEST = 'ELIMINERING_OVERSIKT_REQUEST';
export const OPPHEV_BUNKE_REQUEST = 'OPPHEV_BUNKE_REQUEST';
export const FILTER_CHANGED_ELIMINERING = 'FILTER_CHANGED_ELIMINERING';
export const EKSPORTER_DATAGRUNNLAG_REQUEST = 'EKSPORTER_DATAGRUNNLAG_REQUEST';
export const EKSPORTER_DATAGRUNNLAG_FAILED = 'EKSPORTER_DATAGRUNNLAG_FAILED';
export const EKSPORTER_DATAGRUNNLAG_SUCCESS = 'EKSPORTER_DATAGRUNNLAG_SUCCESS';
export const SORT_MODEL_CHANGED = 'SORT_MODEL_CHANGED';

export const OPPHEV_ELIMINERING_BUNKE_REQUEST = 'OPPHEV_ELIMINERING_BUNKE_REQUEST';
export const OPPHEV_ELIMINERING_BUNKE_SUCCESS = 'OPPHEV_ELIMINERING_BUNKE_SUCCESS';

export const FETCH_POSTER_SUCCESS = 'FETCH_POSTER_SUCCESS';

export const OPPHEV_AVSTEMMING_BUNKER_REQUEST = 'OPPHEV_AVSTEMMING_BUNKER_REQUEST';
export const OPPHEV_AVSTEMMING_BUNKER_SUCCESS = 'OPPHEV_AVSTEMMING_BUNKER_SUCCESS';
export const OPPHEV_AVSTEMMING_BUNKER_FAILED = 'OPPHEV_AVSTEMMING_BUNKER_FAILED';

export const fetchElimineringOversikt = params => ({
    type: ELIMINERING_OVERSIKT_REQUEST,
    params,
});

export const opphevBunkeRequest = bunkeId => ({
    type: OPPHEV_ELIMINERING_BUNKE_REQUEST,
    bunkeId,
});

export const elimineringOpphevingSuccess = () => ({
    type: OPPHEV_ELIMINERING_BUNKE_SUCCESS,
});

export const filterChangedEliminering = (id, value) => ({
    type: FILTER_CHANGED_ELIMINERING,
    id,
    value,
});

export const eksporterDatagrunnlag = params => ({
    type: EKSPORTER_DATAGRUNNLAG_REQUEST,
    params,
});

export const eksporterDatagrunnlagFailed = () => ({
    type: EKSPORTER_DATAGRUNNLAG_FAILED,
});

export const eksporterDatagrunnlagSuccess = () => ({
    type: EKSPORTER_DATAGRUNNLAG_SUCCESS,
});

export const sortModelChanged = sortModel => ({
    type: SORT_MODEL_CHANGED,
    sortModel,
});

export const fetchPosterSuccess = amountOfBunker => ({
    type: FETCH_POSTER_SUCCESS,
    amountOfBunker,
});

export const opphevAvstemmingBunker = () => ({
    type: OPPHEV_AVSTEMMING_BUNKER_REQUEST,
});

export const opphevAvstemmingBunkerSuccess = () => ({
    type: OPPHEV_AVSTEMMING_BUNKER_SUCCESS,
});

export const opphevAvstemmingBunkerFailed = () => ({
    type: OPPHEV_AVSTEMMING_BUNKER_FAILED,
});
