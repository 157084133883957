export const set = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
};

export const retrieve = key => {
    try {
        return JSON.parse(localStorage.getItem(key));
    } catch (error) {
        return null;
    }
};

export const remove = key => localStorage.removeItem(key);

export const exists = key => localStorage.getItem(key) !== null;
