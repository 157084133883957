import { call, put, takeLatest } from 'redux-saga/effects';
import { get } from '../../config/apiConfig';
import { GET_AUTOMATISK_AVSTEMMING_LOGG } from '../../constants/endPoints';
import { displayErrorMessage } from '../actions/errorActions';
import NOB from '../../constants/languageStrings';
import { fetchAutomatiskAvstemmingLoggSuccess, GET_AVSTEMMING_LOGG_REQUEST } from '../actions/automatiskAvstemmingActions';

function* fetchAllSelskaper() {
    try {
        const response = yield call(get, GET_AUTOMATISK_AVSTEMMING_LOGG);
        yield put(fetchAutomatiskAvstemmingLoggSuccess(response.data));
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.GET_AUTOMATISK_AVSTEMMING_LOGG_FAILED));
    }
}

export default [
    takeLatest(GET_AVSTEMMING_LOGG_REQUEST, fetchAllSelskaper),
];
