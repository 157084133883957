import { call, put, takeLatest } from 'redux-saga/effects';
import { FETCH_AUTOMATISK_ELIMINERING_LOGG_REQUEST, fetchAutomatiskElimineringLoggSuccess } from '../actions/automatiskElimineringActions';
import { get } from '../../config/apiConfig';
import { displayErrorMessage } from '../actions/errorActions';
import NOB from '../../constants/languageStrings';
import { GET_AUTOMATISK_ELIMINERING_LOGG } from '../../constants/endPoints';


function* fetchAutomatiskEliminering() {
    try {
        const response = yield call(get, GET_AUTOMATISK_ELIMINERING_LOGG);
        yield put(fetchAutomatiskElimineringLoggSuccess(response.data));
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.GET_AUTOMATISK_ELIMINERING_LOGG_FAILED));
    }
}


export default [
    takeLatest(FETCH_AUTOMATISK_ELIMINERING_LOGG_REQUEST, fetchAutomatiskEliminering),
];
