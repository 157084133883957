export const GET_AVSTEMMING_LOGG_REQUEST = 'GET_AVSTEMMING_LOGG_REQUEST';
export const GET_AVSTEMMING_LOGG_SUCCESS = 'GET_AVSTEMMING_LOGG_SUCCESS';

export const fetchAutomatiskAvstemmingLogg = () => ({
    type: GET_AVSTEMMING_LOGG_REQUEST,
});

export const fetchAutomatiskAvstemmingLoggSuccess = logg => ({
    type: GET_AVSTEMMING_LOGG_SUCCESS,
    logg,
});
