import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { SecureRoute, LoginCallback } from '@okta/okta-react';
import PAGES from '../../constants/pages';

const Dashboard = lazy(() => import('../../modules/dashboard'));
const Authentication = lazy(() => import('../../modules/authentication/authentication'));
const ManuellAvstemming = lazy(() => import('../../modules/manuellAvstemming'));
const Logg = lazy(() => import('../../modules/logg'));
const ManuellElimineringMenu = lazy(() => import('../../modules/manuellEliminering'));
const Regler = lazy(() => import('../../modules/regler'));
const Admin = lazy(() => import('../../modules/admin/admin'));
const Kontoplan = lazy(() => import('../../modules/kontoInformasjon'));
const ElimineringOversikt = lazy(() => import('../../modules/elimineringsOversikt'));
const Feiltabell = lazy(() => import('../../modules/feiltabell'));


function Routes() {
    return (
        <Suspense fallback={<div />}>
            <Switch>
                <Route exact path={PAGES.AUTHENTICATION} component={Authentication} />
                <Route exact path="/login/callback" component={LoginCallback} />
                <SecureRoute exact path={PAGES.DASHBOARD} component={Dashboard} />
                <SecureRoute exact path={PAGES.MANUELL_AVSTEMMING} component={ManuellAvstemming} />
                <SecureRoute exact path={PAGES.ELIMINERING_OVERSIKT} component={ElimineringOversikt} />
                <SecureRoute exact path={PAGES.MANUELL_ELIMINERING} component={ManuellElimineringMenu} />
                <SecureRoute exact path={PAGES.LOGG} component={Logg} />
                <SecureRoute exact path={PAGES.FEILTABELL} component={Feiltabell} />
                <SecureRoute exact path={PAGES.REGLER} component={Regler} />
                <SecureRoute exact path={PAGES.KONTOPLAN} component={Kontoplan} />
                <SecureRoute exact path={PAGES.ADMIN} component={Admin} />
                <Redirect to={PAGES.DASHBOARD} />
            </Switch>
        </Suspense>
    );
}

export default Routes;
