export const FETCH_IKKE_ELIMINERTE_BUNKER_REQUEST = 'FETCH_IKKE_ELIMINERTE_BUNKER_REQUEST';
export const FETCH_IKKE_ELIMINERTE_BUNKER_SUCCESS = 'FETCH_IKKE_ELIMINERTE_BUNKER_SUCCESS';

export const FETCH_BUNKE_REQUEST = 'FETCH_BUNKE_REQEST';
export const FETCH_BUNKE_SUCCESS = 'FETCH_BUNKE_SUCCESS';

export const OVERSIKT_ROW_CLICKED = 'OVERSIKT_ROW_CLICKED';

export const TOGGLE_SEND_VARSEL_REQUEST = 'TOGGLE_SEND_VARSEL_REQUEST';

export const SEND_VARSEL = 'SEND_VARSEL';

export const OPPHEV_ELIMINERING_FOR_REGEL_REQUEST = 'OPPHEV_ELIMINERING_FOR_REGEL_REQUEST';
export const OPPHEV_ELIMINERING_FOR_REGEL_SUCCESS = 'OPPHEV_ELIMINERING_FOR_REGEL_SUCCESS';
export const OPPHEV_ELIMINERING_FOR_REGEL_FAILED = 'OPPHEV_ELIMINERING_FOR_REGEL_FAILED';

export const ELIMINER_POSTER_REQUEST = 'ELIMINER_POSTER_REQUEST';
export const ELIMINER_POSTER_SUCCESS = 'ELIMINER_POSTER_SUCCESS';
export const ELIMINER_POSTER_FAILURE = 'ELIMINER_POSTER_FAILURE';

export const FETCH_FEILBUNKER_REQUEST = 'FETCH_FEILBUNKER_REQUEST';
export const FETCH_FEILBUNKER_SUCCESS = 'FETCH_FEILBUNKER_SUCCESS';
export const FETCH_FEILBUNKER_FAILURE = 'FETCH_FEILBUNKER_FAILURE';

export const SEND_TO_FEILTABELL_REQUEST = 'SEND_TO_FEILTABELL_REQUEST';
export const SEND_TO_FEILTABELL_SUCCESS = 'SEND_TO_FEILTABELL_SUCCESS';
export const SEND_TO_FEILTABELL_FAILURE = 'SEND_TO_FEILTABELL_FAILURE';

export const REMOVE_FROM_FEILTABELL_REQUEST = 'REMOVE_FROM_FEILTABELL_REQUEST';
export const REMOVE_FROM_FEILTABELL_SUCCESS = 'REMOVE_FROM_FEILTABELL_SUCCESS';
export const REMOVE_FROM_FEILTABELL_FAILURE = 'REMOVE_FROM_FEILTABELL_FAILURE';

export const POST_OPPHEV_BUNKE_ELIMINERING_REQUEST = 'POST_OPPHEV_BUNKE_ELIMINERING_REQUEST';
export const POST_OPPHEV_BUNKE_ELIMINERING_SUCCESS = 'POST_OPPHEV_BUNKE_ELIMINERING_SUCCESS';
export const POST_OPPHEV_BUNKE_ELIMINERING_FAILED = 'POST_OPPHEV_BUNKE_ELIMINERING_FAILED';

export const EXPORT_BUNKER_REQUEST = 'EXPORT_BUNKER_REQUEST';
export const EXPORT_BUNKER_SUCCESS = 'EXPORT_BUNKER_SUCCESS';
export const EXPORT_BUNKER_FAILED = 'EXPORT_BUNKER_FAILED';

export const BUNKE_HISTORIKK_REQUEST = 'BUNKE_HISTORIKK_REQUEST';
export const BUNKE_HISTORIKK_SUCCESS = 'BUNKE_HISTORIKK_SUCCESS';

export const BUNKE_IDS_CHANGED = 'BUNKE_IDS_CHANGED';

export const fetchIkkeEliminerteBunkerRequest = () => ({
    type: FETCH_IKKE_ELIMINERTE_BUNKER_REQUEST,
});

export const fetchIkkeEliminerteBunkerSuccess = ikkeEliminerteBunker => ({
    type: FETCH_IKKE_ELIMINERTE_BUNKER_SUCCESS,
    ikkeEliminerteBunker,
});

export const fetchBunkeRequest = bunkeId => ({
    type: FETCH_BUNKE_REQUEST,
    bunkeId,
});

export const fetchBunkeSuccess = bunke => ({
    type: FETCH_BUNKE_SUCCESS,
    bunke,
});

export const oversiktRowClicked = bunkeId => ({
    type: OVERSIKT_ROW_CLICKED,
    bunkeId,
});

export const eliminerPosterRequest = poster => ({
    type: ELIMINER_POSTER_REQUEST,
    poster,
});

export const eliminerPosterSuccess = () => ({
    type: ELIMINER_POSTER_SUCCESS,
});

export const eliminerPosterFailure = () => ({
    type: ELIMINER_POSTER_FAILURE,
});

export const fetchFeilBunkerRequest = () => ({
    type: FETCH_FEILBUNKER_REQUEST,
});

export const fetchFeilBunkerSuccess = bunker => ({
    type: FETCH_FEILBUNKER_SUCCESS,
    bunker,
});

export const fetchFeilBunkerFailure = () => ({
    type: FETCH_FEILBUNKER_FAILURE,
});

export const sendToFeiltabellRequest = (bunkeId, comment) => ({
    type: SEND_TO_FEILTABELL_REQUEST,
    bunkeId,
    comment,
});

export const sendToFeiltabellSuccess = bunkeId => ({
    type: SEND_TO_FEILTABELL_SUCCESS,
    bunkeId,
});

export const sendToFeiltabellFailure = () => ({
    type: SEND_TO_FEILTABELL_FAILURE,
});

export const removeFromFeiltabellRequest = bunkeId => ({
    type: REMOVE_FROM_FEILTABELL_REQUEST,
    bunkeId,
});

export const removeFromFeiltabellSuccess = () => ({
    type: REMOVE_FROM_FEILTABELL_SUCCESS,
});

export const removeFromFeiltabellFailure = () => ({
    type: REMOVE_FROM_FEILTABELL_FAILURE,
});

export const toggleSendVarselRequest = (bunkeId, sendVarselValue) => ({
    type: TOGGLE_SEND_VARSEL_REQUEST,
    bunkeId,
    sendVarselValue,
});

export const opphevElimineringForRegelRequest = regelId => ({
    type: OPPHEV_ELIMINERING_FOR_REGEL_REQUEST,
    regelId,
});

export const opphevElimineringForRegelSuccess = () => ({
    type: OPPHEV_ELIMINERING_FOR_REGEL_SUCCESS,
});

export const opphevElimineringForRegelFailed = () => ({
    type: OPPHEV_ELIMINERING_FOR_REGEL_FAILED,
});
export const sendVarsel = () => ({
    type: SEND_VARSEL,
});

export const postOpphevBunkeElimineringRequest = bunkeId => ({
    type: POST_OPPHEV_BUNKE_ELIMINERING_REQUEST,
    bunkeId,
});

export const postOpphevBunkeElimineringSuccess = () => ({
    type: POST_OPPHEV_BUNKE_ELIMINERING_SUCCESS,
});

export const postOpphevBunkeElimineringFailed = () => ({
    type: POST_OPPHEV_BUNKE_ELIMINERING_SUCCESS,
});

export const fetchExportBunkerRequest = () => ({
    type: EXPORT_BUNKER_REQUEST,
});

export const fetchExportBunkerSuccess = () => ({
    type: EXPORT_BUNKER_SUCCESS,
});

export const fetchExportBunkerFailed = () => ({
    type: EXPORT_BUNKER_FAILED,
});

export const bunkeIdsChanged = bunkeIds => ({
    type: BUNKE_IDS_CHANGED,
    bunkeIds,
});

export const fetchBunkeHistorikkRequest = () => ({
    type: BUNKE_HISTORIKK_REQUEST,
});

export const fetchBunkeHistorikkSuccess = bunker => ({
    type: BUNKE_HISTORIKK_SUCCESS,
    bunker,
});
