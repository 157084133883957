const BASE_URL = process.env.TAS_REST_BASE_URL;

// Selskap
export const GET_SELSKAP_LIST = `${BASE_URL}/selskap`;
export const GET_SELSKAP = periode => `${BASE_URL}/selskap/${periode}`;
export const CHANGE_FAVOURITE = selskapNr => `${BASE_URL}/selskap/favoritt/${selskapNr}`;
export const GET_MOTPARTER_FOR_SELSKAP = selskapNr => `${BASE_URL}/selskap/${selskapNr}/motpart`;
export const GET_SELSKAP_INFO = (periode, selskapNr) => `${BASE_URL}/selskap/${periode}/${selskapNr}`;

// Avstemming
export const GET_POSTER = `${BASE_URL}/post`;
export const AVSTEM_POSTER = `${BASE_URL}/post/avstem`;
export const POSTKOMMENTAR = postId => `${BASE_URL}/post/kommentar/${postId}`;
export const GET_HISTORY = postId => `${BASE_URL}/post/bunke/${postId}`;
export const OPPHEV_BUNKE = bunkeId => `${BASE_URL}/bunke/${bunkeId}/opphev`;
export const GET_AUTOMATISK_AVSTEMMING_LOGG = `${BASE_URL}/logg/avstemming`;
export const OPPHEV_BUNKER = `${BASE_URL}/bunke/opphev`;

// Periode
export const GET_PERIODER = periodestatus => `${BASE_URL}/periode/${periodestatus}`;
export const GET_ALL_PERIODER = `${BASE_URL}/periode/`;

// Regler
export const REGEL = `${BASE_URL}/regel`;
export const REGEL_ID = regelId => `${BASE_URL}/regel/${regelId}`;
export const KRITERIUM = regelId => `${BASE_URL}/regel/${regelId}/kriterium`;
export const DELETE_KRITERIUM = kriteriumId => `${BASE_URL}/regel/kriterium/${kriteriumId}`;
export const REGEL_PRIORITY_UPDATE = `${BASE_URL}/regel/prioritet`;
export const POST_KJOR_REGEL = regelId => `${BASE_URL}/regel/${regelId}/kjor`;
export const POST_OPPHEV_REGEL = regelId => `${BASE_URL}/regel/${regelId}/opphev`;
export const AVSTEMMER_AVAILABLE = `${BASE_URL}/avstemming/state/available`;

// Kontoplan
export const IG_KONTO = `${BASE_URL}/igKonto`;
export const ADD_KONSERNKONTO_TO_IG_KONTO = (igKontoId,
    konsernkontoId) => `${BASE_URL}/igKonto/add/${igKontoId}/${konsernkontoId}`;
export const REMOVE_KONSERNKONTO_FROM_IG_KONTO = konsernkontoId => `${BASE_URL}/igKonto/remove/${konsernkontoId}`;
export const UPDATE_EKSTERN_MOTKONTO = (igKontoId, eksternMotkonto) => `${BASE_URL}/igKonto/${igKontoId}/ekstern-motkonto/${eksternMotkonto}`;
export const KONSERNKONTO = `${BASE_URL}/konsernkonto`;
export const KONTOKOMBINASJON = `${BASE_URL}/eliminering/kontokombinasjon`;
export const KONTOKOMBINASJON_DELETE = kontokombinasjonId => `${BASE_URL}/eliminering/kontokombinasjon/${kontokombinasjonId}`;

// Admin
export const VALIDERINGSBEGRUNNELSE = `${BASE_URL}/valideringsbegrunnelse`;
export const UPDATE_VALIDERINGSBEGRUNNELSE = valideringsbegrunnelseId => `${BASE_URL}/valideringsbegrunnelse/${valideringsbegrunnelseId}`;

// Elimineringsoversikt
export const OPPHEV_BUNKE_ELIMINERING = bunkeId => `${BASE_URL}/eliminering/opphev/${bunkeId}`;
export const ELIMINERING_OVERSIKT_POST = `${BASE_URL}/post/bunkeinfo`;

// Eksport
export const OPEN_NEW_PERIODER = `${BASE_URL}/periode`;
export const CREATE_HFM_EXPORT = `${BASE_URL}/eksport/hfm`;
export const DOWNLOAD_HFM_EXPORT = reportId => `${BASE_URL}/eksport/hfm/${reportId}`;
export const EKSPORTER_DATAGRUNNLAG = `${BASE_URL}/eksport/datagrunnlag`;
export const EXPORT_BUNKER = `${BASE_URL}/eksport/bunker`;

// Eliminering
export const IKKE_ELIMINERTE_BUNKER = `${BASE_URL}/bunke/ikkeeliminert`;
export const GET_BUNKE = bunkeId => `${BASE_URL}/bunke/${bunkeId}`;
export const ELIMINER_POSTER = `${BASE_URL}/eliminering`;
export const GET_AUTOMATISK_ELIMINERING_LOGG = `${BASE_URL}/logg/eliminering`;
export const BUNKE_HISTORIKK = `${BASE_URL}/bunke/history`;

// Feiltabell
export const FEILBUNKER = `${BASE_URL}/bunke/feilbunker`;
export const SEND_TIL_FEILTABELL = bunkeId => `${BASE_URL}/bunke/${bunkeId}/status/FEIL`;
export const FJERN_FRA_FEILTABELL = bunkeId => `${BASE_URL}/bunke/${bunkeId}/status/AKTIV`;
export const TOGGLE_SEND_VARSEL = (bunkeId, sendVarsel) => `${BASE_URL}/bunke/${bunkeId}/sendVarsel/${sendVarsel}`;
export const POST_SEND_VARSEL = `${BASE_URL}/bunke/sendVarsel`;

// Sletteregler/Sletteposter
export const SLETTEREGLER = `${BASE_URL}/slett/regler`;
export const GJENOPPRETT_POSTER = sletteregelId => `${BASE_URL}/slett/gjenopprett/${sletteregelId}`;
export const DELETE_SLETTEREGEL = sletteregelId => `${BASE_URL}/slett/regler/${sletteregelId}`;
export const RUN_SLETTEREGEL = sletteregelId => `${BASE_URL}/slett/${sletteregelId}`;
export const CHECK_SLETTEREGEL = sletteregelId => `${BASE_URL}/slett/check/${sletteregelId}`;
