import { useOktaAuth } from '@okta/okta-react';
import { useEffect, useState } from 'react';

function useAuthentication() {
    const { authState, oktaAuth } = useOktaAuth();
    const [userInfo, setUserInfo] = useState(null);
    useEffect(() => {
        if (!authState.isAuthenticated) {
            setUserInfo(null);
        } else {
            oktaAuth.getUser()
                .then(info => {
                    setUserInfo(info);
                });
        }
    }, [authState, oktaAuth]); // Update if authState changes

    return userInfo;
}

export default useAuthentication;
