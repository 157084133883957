export const REGLER_FETCH_REQUEST = 'REGLER_FETCH_REQUEST';
export const REGLER_FETCH_SUCCESS = 'REGLER_FETCH_SUCCESS';

export const REGEL_FETCH_REQUEST = 'REGEL_ID_FETCH_REQUEST';
export const REGEL_FETCH_SUCCESS = 'REGEL_ID_FETCH_SUCCESS';
export const REGEL_FETCH_FAILURE = 'REGEL_FETCH_FAILURE';

export const SAVE_NEW_REGEL_REQUEST = 'SAVE_NEW_REGEL_REQUEST';
export const SAVE_NEW_BETINGELSE_REQUEST = 'SAVE_NEW_BETINGELSE_REQUEST';

export const DELETE_KRITERIER_REQUEST = 'DELETE_KRITERIER_REQUEST';
export const DELETE_KRITERIER_SUCCESS = 'DELETE_KRTIERIER_SUCCESS';

export const UPDATE_REGEL_PRIORITY = 'UPDATE_REGEL_PRIORITY';

export const REGEL_TOGGLE_ACTIVE_REQUEST = 'REGEL_TOGGLE_ACTIVE_REQUEST';
export const REGEL_TOGGLE_ACTIVE_SUCCESS = 'REGEL_TOGGLE_ACTIVE_SUCCESS';

export const REGEL_TOGGLE_MVA_REQUEST = 'REGEL_TOGGLE_MVA_REQUEST';
export const REGEL_TOGGLE_MVA_SUCCESS = 'REGEL_TOGGLE_MVA_SUCCESS';

export const UPDATE_REGEL_REQUEST = 'UPDATE_REGEL_REQUEST';

export const ELIMINERING_KRITERIUM_UPDATE_REQUEST = 'ELIMINERING_KRITERIUM_UPDATE_REQUEST';
export const ELIMINERING_KRITERIUM_UPDATE_SUCCESS = 'ELIMINERING_KRITERIUM_UPDATE_SUCCESS';

export const AVSTEMMING_KRITERIUM_UPDATE_REQUEST = 'AVSTEMMING_KRITERIUM_UPDATE_REQUEST';
export const AVSTEMMING_KRITERIUM_UPDATE_SUCCESS = 'AVSTEMMING_KRITERIUM_UPDATE_SUCCESS';

export const KJOR_REGEL = 'KJOR_REGEL';

export const OPPHEV_FOR_REGEL_REQUEST = 'OPPHEV_FOR_REGEL_REQUEST';

export const FETCH_AVSTEMMER_AVAILABLE = 'AVSTEMMER_AVAILABLE';
export const FETCH_AVSTEMMER_AVAILABLE_SUCCESS = 'FETCH_AVSTEMMER_AVAILABLE_SUCCESS';


export const fetchReglerRequest = () => ({
    type: REGLER_FETCH_REQUEST,
});

export const fetchReglerSuccess = regler => ({
    type: REGLER_FETCH_SUCCESS,
    regler,
});

export const fetchRegelRequest = regelId => ({
    type: REGEL_FETCH_REQUEST,
    regelId,
});

export const fetchRegelSuccess = kriterier => ({
    type: REGEL_FETCH_SUCCESS,
    kriterier,
});

export const fetchRegelFailure = () => ({
    type: REGEL_FETCH_FAILURE,
});

export const saveNewRegelRequest = (tittel, status, mva, prioritet) => ({
    type: SAVE_NEW_REGEL_REQUEST,
    tittel,
    status,
    prioritet,
    mva,
});

export const saveNewBetingelseRequest = (regel, betingelse, motbetingelse) => ({
    type: SAVE_NEW_BETINGELSE_REQUEST,
    regel,
    betingelse,
    motbetingelse,
});

export const deleteKriterierRequest = (regelId, kriteriumId) => ({
    type: DELETE_KRITERIER_REQUEST,
    regelId,
    kriteriumId,
});

export const kriteriumDeleteSuccess = kriteriumId => ({
    type: DELETE_KRITERIER_SUCCESS,
    kriteriumId,
});

export const updateRegelPriorityRequest = regler => ({
    type: UPDATE_REGEL_PRIORITY,
    regler,
});

export const toggleRegelActiveRequest = regel => ({
    type: REGEL_TOGGLE_ACTIVE_REQUEST,
    regel,
});

export const toggleRegelActiveSuccess = () => ({
    type: REGEL_TOGGLE_ACTIVE_SUCCESS,
});

export const toggleRegelMvaRequest = regel => ({
    type: REGEL_TOGGLE_MVA_REQUEST,
    regel,
});

export const toggleRegelMvaSuccess = () => ({
    type: REGEL_TOGGLE_MVA_SUCCESS,
});

export const updateRegelRequest = regel => ({
    type: UPDATE_REGEL_REQUEST,
    regel,
});

export const addUpdateElimineringKriteriumSuccess = () => ({
    type: ELIMINERING_KRITERIUM_UPDATE_SUCCESS,
});

export const addUpdateAvstemmingKriterium = (regel, kriterium) => ({
    type: AVSTEMMING_KRITERIUM_UPDATE_REQUEST,
    regel,
    kriterium,
});

export const addUpdateAvstemmingKriteriumSuccess = () => ({
    type: AVSTEMMING_KRITERIUM_UPDATE_SUCCESS,
});

export const kjorRegel = regelId => ({
    type: KJOR_REGEL,
    regelId,
});

export const opphevForRegel = regelId => ({
    type: OPPHEV_FOR_REGEL_REQUEST,
    regelId,
});

export const avstemmerIsAvailable = () => ({
    type: FETCH_AVSTEMMER_AVAILABLE,
});

export const avstemmerIsAvailableSuccess = status => ({
    type: FETCH_AVSTEMMER_AVAILABLE_SUCCESS,
    status,
});
