import { call, put, takeLatest } from 'redux-saga/effects';
import { get, put as apiPut, post, httpDelete } from '../../config/apiConfig';
import { ADD_KONSERNKONTO_TO_IG_KONTO,
    UPDATE_EKSTERN_MOTKONTO,
    IG_KONTO,
    KONSERNKONTO,
    REMOVE_KONSERNKONTO_FROM_IG_KONTO, KONTOKOMBINASJON, KONTOKOMBINASJON_DELETE } from '../../constants/endPoints';
import { ADD_IG_KONTO_REQUEST,
    ADD_KONSERNKONTO_TO_IG_KONTO_REQUEST,
    UPDATE_EKSTERN_MOTKONTO_REQUEST,
    FETCH_IG_KONTOER_REQUEST,
    FETCH_KONSERNKONTOER_REQUEST,
    fetchIgKontoerRequest,
    fetchIgKontoerSuccess,
    fetchKonsernkontoerRequest,
    fetchKonsernkontoerSuccess,
    REMOVE_KONSERNKONTO_FROM_IG_KONTO_REQUEST,
    TOGGLE_ELIMINERES_REQUEST,
    TOGGLE_MANUELL_BEHANDLING_REQUEST,
    UPDATE_KONTOKOMBINAJSON, FETCH_KONTOKOMBINASJON_REQUEST, fetchKontokombinasjonSuccess, fetchKontokombinasjonRequest, DELETE_KONTOKOMBINASJON_REQUEST } from '../actions/kontoplanActions';
import { displayErrorMessage } from '../actions/errorActions';
import NOB from '../../constants/languageStrings';

function* fetchIgKontoer() {
    try {
        const response = yield call(get, IG_KONTO);
        yield put(fetchIgKontoerSuccess(response.data));
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.FETCH_IG_KONTO_FAILED));
    }
}

function* addKonsernkontoToIgKonto({ igKontoId, konsernkontoId }) {
    try {
        yield call(apiPut, ADD_KONSERNKONTO_TO_IG_KONTO(igKontoId, konsernkontoId));
        yield put(fetchIgKontoerRequest());
        yield put(fetchKonsernkontoerRequest());
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.ADD_KONSERNKONTO_TO_IG_KONTO_FAILED));
    }
}

function* addIgKonto(igKonto) {
    try {
        yield call(post, IG_KONTO, igKonto.igKonto);
        yield put(fetchIgKontoerRequest());
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.ADD_KONSERNKONTO_TO_IG_KONTO_FAILED));
    }
}

function* fetchKonsernkontoer() {
    try {
        const response = yield call(get, KONSERNKONTO);
        yield put(fetchKonsernkontoerSuccess(response.data));
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.FETCH_KONSERNKONTOER_FAILED));
    }
}

function* removeKonsernkontoFromIgKonto(konsernkontoId) {
    try {
        yield call(apiPut, REMOVE_KONSERNKONTO_FROM_IG_KONTO(konsernkontoId.konsernkontoId));
        yield put(fetchIgKontoerRequest());
        yield put(fetchKonsernkontoerRequest());
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.REMOVE_KONSERNKONTO_FROM_IG_KONTO_FAILED));
    }
}

function* updateEksternMotkonto({ igKontoId, eksternMotkonto }) {
    try {
        yield call(apiPut, UPDATE_EKSTERN_MOTKONTO(igKontoId, eksternMotkonto));
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.UPDATE_EKSTERN_MOTKONTO_FAILED));
    }
}

function* fetchKontokombinasjon() {
    try {
        const response = yield call(get, KONTOKOMBINASJON);
        yield put(fetchKontokombinasjonSuccess(response.data));
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.FETCH_KONTOKOMBINASJON_FAILED));
    }
}

function* updateKontoKombinasjon({ elimineringKontokombinasjon }) {
    try {
        yield call(post, KONTOKOMBINASJON, elimineringKontokombinasjon);
        yield put(fetchKontokombinasjonRequest());
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.UPDATE_ELIMINERING_KONTO_KOMBINASJON_FAILED));
    }
}

function* deleteKontokombinasjon({ kontokombinasjonId }) {
    try {
        yield call(httpDelete, KONTOKOMBINASJON_DELETE(kontokombinasjonId));
        yield put(fetchKontokombinasjonRequest());
    } catch (error) {
        yield put(displayErrorMessage(NOB.ERROR.DELETE_ELIMINERING_KONTO_KOMBINASJON_FAILED));
    }
}

export default [
    takeLatest(FETCH_IG_KONTOER_REQUEST, fetchIgKontoer),
    takeLatest(ADD_KONSERNKONTO_TO_IG_KONTO_REQUEST, addKonsernkontoToIgKonto),
    takeLatest(ADD_IG_KONTO_REQUEST, addIgKonto),
    takeLatest(FETCH_KONSERNKONTOER_REQUEST, fetchKonsernkontoer),
    takeLatest(REMOVE_KONSERNKONTO_FROM_IG_KONTO_REQUEST, removeKonsernkontoFromIgKonto),
    takeLatest(UPDATE_EKSTERN_MOTKONTO_REQUEST, updateEksternMotkonto),
    takeLatest(TOGGLE_ELIMINERES_REQUEST, updateKontoKombinasjon),
    takeLatest(TOGGLE_MANUELL_BEHANDLING_REQUEST, updateKontoKombinasjon),
    takeLatest(UPDATE_KONTOKOMBINAJSON, updateKontoKombinasjon),
    takeLatest(FETCH_KONTOKOMBINASJON_REQUEST, fetchKontokombinasjon),
    takeLatest(DELETE_KONTOKOMBINASJON_REQUEST, deleteKontokombinasjon),
];
