// Action types
export const FETCH_VALIDERINGSBEGRUNNELSER_REQUEST = 'FETCH_VALIDERINGSBEGRUNNELSER_REQUEST';
export const FETCH_VALIDERINGSBEGRUNNELSER_SUCCESS = 'FETCH_VALIDERINGSBEGRUNNELSER_SUCCESS';

export const SAVE_VALIDERINGSBEGRUNNELSE_REQUEST = 'SAVE_VALIDERINGSBEGRUNNELSE_REQUEST';
export const SAVE_VALIDERINGSBEGRUNNELSE_SUCCESS = 'SAVE_VALIDERINGSBEGRUNNELSE_SUCCESS';

export const UPDATE_VALIDERINGSBEGRUNNELSE_REQUEST = 'UPDATE_VALIDERINGSBEGRUNNELSE_REQUEST';
export const UPDATE_VALIDERINGSBEGRUNNELSE_SUCCESS = 'UPDATE_VALIDERINGSBEGRUNNELSE_SUCCESS';

export const APPROVE_HFM_EXPORT_REQUEST = 'APPROVE_HFM_EXPORT_REQUEST';
export const APPROVE_HFM_EXPORT_SUCCESS = 'APPROVE_HFM_EXPORT_SUCCESS';

export const CREATE_HFM_EXPORT_REQUEST = 'CREATE_HFM_EXPORT_REQUEST';
export const CREATE_HFM_EXPORT_SUCCESS = 'CREATE_HFM_EXPORT_SUCCESS';

export const FETCH_LATEST_HFM_EXPORT_REQUEST = 'FETCH_LATEST_HFM_EXPORT_REQUEST';
export const FETCH_LATEST_HFM_EXPORT_SUCCESS = 'FETCH_LATEST_HFM_EXPORT_SUCCESS';

export const POLL_LATEST_HFM_EXPORT = 'POLL_LATEST_HFM_EXPORT';

export const DOWNLOAD_HFM_EXPORT_REQUEST = 'DOWNLOAD_HFM_EXPORT_REQUEST';

export const FETCH_SLETTEREGLER_REQUEST = 'FETCH_SLETTEREGLER_REQUEST';
export const FETCH_SLETTEREGLER_SUCCESS = 'FETCH_SLETTEREGLER_SUCCESS';

export const GJENOPPRETT_POSTER_REQUEST = 'GJENOPPRETT_POSTER_REQUEST';
export const GJENOPPRETT_POSTER_SUCCESS = 'GJENOPPRETT_POSTER_SUCCESS';

export const RUN_REGEL_REQUEST = 'RUN_REGEL_REQUEST';
export const RUN_REGEL_SUCCESS = 'RUN_REGEL_SUCCESS';

export const CHECK_RUN_REGEL_EFFECT_REQUEST = 'CHECK_RUN_REGEL_EFFECT_REQUEST';
export const CHECK_RUN_REGEL_EFFECT_SUCCESS = 'CHECK_RUN_REGEL_EFFECT_SUCCESS';

export const UPDATE_SLETTEREGEL_REQUEST = 'UPDATE_SLETTEREGEL_REQUEST';

export const DELETE_SLETTEREGEL_REQUEST = 'DELETE_SLETTEREGEL_REQUEST';

export const ADD_SLETTEREGEL_REQUEST = 'ADD_SLETTEREGEL_REQUEST';

// Action creators
export const fetchValideringsbegrunnelserRequest = () => ({
    type: FETCH_VALIDERINGSBEGRUNNELSER_REQUEST,
});

export const fetchValideringsbegrunnelserSuccess = valideringsbegrunnelser => ({
    type: FETCH_VALIDERINGSBEGRUNNELSER_SUCCESS,
    valideringsbegrunnelser,
});


export const saveValideringsbegrunnelseRequest = valideringsbegrunnelse => ({
    type: SAVE_VALIDERINGSBEGRUNNELSE_REQUEST,
    valideringsbegrunnelse,
});

export const saveValideringsbegrunnelseSuccess = () => ({
    type: SAVE_VALIDERINGSBEGRUNNELSE_SUCCESS,
});

export const updateValideringsbegrunnelseRequest = (id, valideringsbegrunnelse) => ({
    type: UPDATE_VALIDERINGSBEGRUNNELSE_REQUEST,
    id,
    valideringsbegrunnelse,
});

export const updateValideringsbegrunnelseSuccess = () => ({
    type: UPDATE_VALIDERINGSBEGRUNNELSE_SUCCESS,
});

export const approveHFMExportRequest = () => ({
    type: APPROVE_HFM_EXPORT_REQUEST,
});

export const approveHFMExportSuccess = () => ({
    type: APPROVE_HFM_EXPORT_SUCCESS,
});

export const createHfmExportRequest = () => ({
    type: CREATE_HFM_EXPORT_REQUEST,
});

export const createHfmExportSuccess = () => ({
    type: CREATE_HFM_EXPORT_SUCCESS,
});

export const fetchLatestHfmExportRequest = () => ({
    type: FETCH_LATEST_HFM_EXPORT_REQUEST,
});

export const fetchLatestHfmExportSuccess = exportMetadata => ({
    type: FETCH_LATEST_HFM_EXPORT_SUCCESS,
    exportMetadata,
});

export const pollLatestHfmExport = () => ({
    type: pollLatestHfmExport,
});

export const downloadHfmExportRequest = () => ({
    type: DOWNLOAD_HFM_EXPORT_REQUEST,
});

export const fetchSlettereglerRequest = () => ({
    type: FETCH_SLETTEREGLER_REQUEST,
});

export const fetchSlettereglerSuccess = sletteregler => ({
    type: FETCH_SLETTEREGLER_SUCCESS,
    sletteregler,
});

export const gjenopprettPosterRequest = (sletteregelId, fromDate) => ({
    type: GJENOPPRETT_POSTER_REQUEST,
    sletteregelId,
    fromDate,
});

export const runRegelRequest = (sletteregelId, fromDate) => ({
    type: RUN_REGEL_REQUEST,
    sletteregelId,
    fromDate,
});

export const checkRunRegelEffectRequest = (sletteregelId, fromDate) => ({
    type: CHECK_RUN_REGEL_EFFECT_REQUEST,
    sletteregelId,
    fromDate,
});

export const checkRunRegelEffectSuccess = amountOfPoster => ({
    type: CHECK_RUN_REGEL_EFFECT_SUCCESS,
    amountOfPoster,
});

export const runRegelSuccess = () => ({
    type: RUN_REGEL_SUCCESS,
});

export const gjenopprettPosterSuccess = () => ({
    type: GJENOPPRETT_POSTER_SUCCESS,
});

export const addSletteregelRequest = (betingelse, fromDate) => ({
    type: ADD_SLETTEREGEL_REQUEST,
    betingelse,
    fromDate,
});

export const updateSletteregelRequest = (sletteregelId, betingelse) => ({
    type: UPDATE_SLETTEREGEL_REQUEST,
    sletteregelId,
    betingelse,
});

export const deleteSletteregelRequest = sletteregelId => ({
    type: DELETE_SLETTEREGEL_REQUEST,
    sletteregelId,
});
