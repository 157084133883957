const NOB = {
    APPNAME: {
        FIRST: 'Transaksjons-',
        SECOND: 'Avstemmings-',
        THIRD: 'Systemet',
    },
    MENU: {
        DASHBOARD: 'Dashboard',
        MANUELL_AVSTEMMING: 'Manuell avstemming',
        REGLER: 'Regler',
        KONTOPLAN: 'Kontoplan',
        ELIMINERING_OVERSIKT: 'Eliminering oversikt',
        LOGOUT: 'Logg ut',
        ADMIN: 'Admin',
        FEILTABELL: 'Feiltabell',
        MANUELL_ELIMINERING: 'Manuell eliminering',
        NO_ACCESS: 'Du har ikke tilgang til TAS. Kontakt tjenesteportalen på telefon 951 29 999 for å få tilgang.',
        LOGG: 'Logg',
    },
    ERROR: {
        DEFAULT_POSTFIX: ' Prøv igjen. Dersom feilen vedvarer, kontakt tjenesteportalen på telefon 951 29 999.',
        PERIODER_FAILED: 'Klarte ikke å hente perioder.',
        SELSKAP_LIST_FAILED: 'Klarte ikke å hente selskaper for periode.',
        SELSKAP_INFO_FAILED: 'Klarte ikke å hente poster for selskap i periode.',
        POST_LIST_FAILED: 'Klarte ikke å hente poster.',
        POST_AVSTEM_FAILED: 'Klarte ikke å avstemme poster.',
        ALL_SELSKAP_LIST_FAILED: 'Klarte ikke å hente selskaper.',
        MOTPARTER_FAILED: 'Klarte ikke å hente motparter for selskap.',
        TOGGLE_FAVOURITE_FAILED: 'Klarte ikke å endre favoritter',
        FETCH_POSTKOMMENTAR_FAILED: 'Klarte ikke hente kommentarer.',
        SAVE_POSTKOMMENTAR_FAILED: 'Klarte ikke å lagre kommentar.',
        FETCH_REGLER_FAILED: 'Klarte ikke hente regler.',
        UPDATE_KRITERIUM_FAILED: 'Klarte ikke oppdatere kriterium. ',
        DELETE_KRITERIUM_FAILED: 'Klarte ikke å slette kriterium. ',
        POST_HISTORY_FAILED: 'Klarte ikke å hente post historikk. ',
        SAVE_REGEL_FAILED: 'Klarte ikke å lagre regel.',
        SAVE_BETINGELSE_FAILED: 'Klarte ikke å lagre betingelse. ',
        OPPHEV_BUNKE_FAILED: 'Klarte ikke å oppheve bunken.',
        FETCH_IG_KONTO_FAILED: 'Klarte ikke hente igKontoer.',
        ADD_KONSERNKONTO_TO_IG_KONTO_FAILED: 'Klarte ikke legge til konsernkonto til igKonto.',
        REMOVE_KONSERNKONTO_FROM_IG_KONTO_FAILED: 'Klarte ikke fjerne konsernkonto fra igKonto.',
        FETCH_KONSERNKONTOER_FAILED: 'Klarte ikke hente konsernkontoer.',
        FETCH_VALIDERINGSBEGRUNNELSER_FAILED: 'Klarte ikke hente valideringsbegrunnelser.',
        SAVE_VALIDERINGSBEGRUNNELSER_FAILED: 'Klarte ikke lagre valideringsbegrunnelse.',
        UPDATE_VALIDERINGSBEGRUNNELSER_FAILED: 'Klarte ikke oppdatere valideringsbegrunnelse.',
        GET_AUTOMATISK_AVSTEMMING_LOGG_FAILED: 'Klarte ikke hente oversikt over kjøringer av automatisk avstemming.',
        CHANGE_PRIORITY_FAILED: 'Klarte ikke å endre rekkefølgen på reglene.',
        UPDATE_PERIODE_FAILED: 'Klarte ikke oppdatere periode.',
        OPEN_NEW_PERIODE_INVALID: 'Klarer ikke godkjenne eksport og låse periode. ',
        OPEN_NEW_PERIODE_FAILED: 'Kan ikke godkjenne eksport og låse periode før perioden er ferdig. ',
        UPDATE_REGEL_FAILED: 'Klarte ikke å endre regel.',
        FETCH_IKKE_ELIMINERTE_BUNKER_FAILED: 'Klarte ikke hente ikke-eliminerte bunker.',
        FETCH_BUNKE_ERROR: 'Klarte ikke å hente bunke.',
        UPDATE_IG_KONTO_ERROR: 'Klarte ikke å oppdatere ig-konto for post.',
        UPDATE_BELOP_ERROR: 'Klarte ikke å oppdatere beløp for post.',
        UPDATE_OMPOSTERING_ERROR: 'Klarte ikke å oppdatere ompostering for post.',
        RESET_POSTER: 'Klarte ikke å tilbakestille poster i bunke.',
        SET_TO_ZERO: 'Klarte ikke sette beløpsfelter til 0 for poster i bunke.',
        UPDATE_EKSTERN_MOTKONTO_FAILED: 'Klarte ikke oppdatere ekstern motkonto. ',
        OPPHEV_ELIMINERING_FOR_REGEL_FAILED: 'Klarte ikke oppheve eliminering for regel. ',
        ELIMINER_POSTER_ERROR: 'Klarte ikke eliminere poster. Verifiser at IG-konto eksisterer. ',
        EXPORT_TIL_KONSERNKONTO_FAILED: 'Klarte ikke exportere poster til Konsernkonto',
        CREATE_HFM_EXPORT: 'Klarte ikke å lage excel-fil for eksport til HFM.',
        HFM_EXPORT_HAS_IOEXCEPTION: 'Noe gikk galt under generering av forrige HFM-export. Prøv igjen.',
        HFM_EXPORT_HAS_ILLEGALARGUMENTEXCEPTION: 'Noe gikk galt under generering av forrige HFM-eksport. Minst en post mangler IG-konto, eller det finnes ugyldige kontoer i elimineringen.',
        SEND_VARSEL_ERROR: 'Send varsel feilet.',
        RUN_REGEL_FAILED: 'Kunne ikke kjøre regel',
        OPPHEV_REGEL_FAILED: 'Klarte ikke å oppheve avstemming for regel',
        CREATE_DATAGRUNNLAG: 'Klarte ikke å lage excel-fil for eksport av datagrunnlag.',
        GET_AUTOMATISK_ELIMINERING_LOGG_FAILED: 'Klarte ikke hente oversikt over kjøringer av automatisk eliminering.',
        UPDATE_ELIMINERING_KONTO_KOMBINASJON_FAILED: 'Klarte ikke å oppdatere kontokombinasjoner',
        FETCH_KONTOKOMBINASJON_FAILED: 'Klarte ikke å hente kontokombinasjoner',
        DELETE_ELIMINERING_KONTO_KOMBINASJON_FAILED: 'Klarte ikke å slette kontokombinasjoner',
        FETCH_SLETTEREGLER: 'Klarte ikke å hente sletteregler',
        GJENOPPRETT_POSTER: 'Klarte ikke å gjenopprette poster',
        RUN_SLETTEREGEL: 'Klarte ikke å kjøre sletteregel',
        CHECK_RUN_SLETTEREGEL: 'Klarte ikke å hente antall poster til sletting',
        ADD_SLETTEREGEL: 'Klarte ikke å legge til sletteregel',
        UPDATE_SLETTEREGEL: 'Klarte ikke å oppdatere sletteregel',
        DELETE_SLETTEREGEL: 'Klarte ikke å slette sletteregel',
        FETCH_AVSTEMMER_AVAILABLE: 'Klarte ikke å hente status til automatisk avstemmer',
        EXPORT_BUNKER_ERROR: 'Klarte ikke å hente excel-uttrekk av bunker',
        OPPHEV_AVSTEMMING_BUNKER: 'Klarte ikke å oppheve avstemming for valgte bunker',
        BUNKE_HISTORIKK_ERROR: 'Klarte ikke hente eliminerte bunker.',
    },
    MANUELL_AVSTEMMING: {
        SELSKAP: 'Selskap',
        MOTPART: 'Motpart',
        PERIODE: 'Periode',
        STATUS: 'Post-status',
        SEARCH: 'Søk',
        CUSTOM_KOMMENTAR_LABEL: 'Skriv inn egen kommentar...',
        PREDEFINED_KOMMENTAR_LABEL: 'Velg forhåndsdefinert kommentar...',
        FRAVIKELIG_REGLER_MESSAGE: 'Følgende fravikelige regler er brutt:',
        AVSTEMMING_CONFIRMATION: 'Er du sikker på at du ønsker å avstemme de valgte postene?',
        AVSTEM_POSTER: 'Avstem poster',
        MARK_ALL_ROWS: 'Velg alle rader',
        REMOVE_MARKINGS: 'Fjern alle markeringer',
        HELPTEXT_UNDER_100_ROWS: 'Må ha under 100 rader',
        HELPTEXT_OVER_1_ROW: 'Må ha minst en rad',
        SUMMERING_FELTER: 'Summering felter',
    },
    REGLER: {
        REGLER: 'Regler',
        NEW_REGEL: 'Ny regel',
        BETINGELSER: 'Betingelser',
        NEW_BETINGELSE: 'Ny betingelse',
        EDIT_BETINGELSER: 'Endre / legg til betingelser',
        CHANGE_PRIORITET: 'Endre Prioritet',
        OPPHEV_ELIMINERING_FOR_REGEL: 'Opphev eliminering',
        OPPHEVER_ELIMINERING_FOR_REGEL: 'Opphever eliminering',
        KJOR_REGEL: 'Avstem med valgt regel',
        ELIMINERING_REGLER: 'Eliminerings regel kontoer',
        OPPHEV_AVSTEMMING_FOR_REGEL: 'Opphev avstemming for regel',
        HJELPE_TEKST_AUTOAVSTEMMER_KJORER: 'Avstemming av regel kan ikke kjøres mens autoavstemmeren allerede kjøres',
    },
    BETINGELSER: {
        SELSKAP: 'Betingelser for selskap',
        MOTPART: 'Betingelser for motpart',
    },
    POST_HISTORY: {
        POST_HISTORY: 'Den markerte posten inngår i følgende bunker',
        POST_HISTORY_LIST: 'Poster som inngår i valgt bunke',
        OPPHEV_BUNKE_TEXT: 'Er du sikker på at du vil oppheve den markerte bunken?',
        OPPHEV: 'Opphev bunke',
        BUNKE_OPPHEVET: 'Bunke opphevet',
    },
    COMMENT: {
        INPUT: 'Skriv en kommentar',
        ADD: 'Legg til',
    },
    CONFIRMATION_MODAL: {
        DECLINE: 'Avbryt',
        CONFIRM: 'Bekreft',
        MVA: 'MVA',
    },
    VALIDERINGSBEGRUNNELSE: {
        VALIDERINGSBEGRUNNELSE: 'Valideringsbegrunnelse',
        CREATE_OR_EDIT_VALIDERINGSBEGRUNNELSER: 'Opprett eller endre valideringsbegrunnelser',
        NEW_VALIDERINGSBEGRUNNELSE: 'Ny valideringsbegrunnelse',
    },
    BUTTONS: {
        SAVE: 'Lagre',
        DECLINE: 'Avbryt',
    },
    AUTOMATISK_AVSTEMMING: {
        HEADER: 'Logg',
    },
    AUTOMATISK_ELIMINERING: {
        HEADER: 'Logg',
    },
    KONTOPLAN: {
        NEW_IG_KONTO: 'Ny Ig-Konto',
        KONTONAVN: 'Kontonavn',
        KONTO_NR: 'Kontonummer',
        IG_KONTOER: 'Ig-kontoer',
        KONSERNKONTOER_WITHOUT_IG_KONTO: 'Konsernkontoer som ikke tilhører en Ig-konto',
        NEW_KONTOKOMBINASJON: 'Ny kontokombinasjon',
    },
    MANUELL_ELIMINERING: {
        OVERVIEW_IKKE_ELIMINERTE_BUNKER: 'Bunker til eliminering',
        BUNKE_KOMMENTAR: 'Kommentar: ',
        POSTER_FOR_ELIMINERING: 'Poster til eliminering',
        SET_TO_ZERO: 'Sett nettobeløp til null',
        RESET: 'Tilbakestill',
        PREVIOUS_BUNKE: 'Forrige bunke',
        NEXT_BUNKE: 'Neste bunke',
        BUNKE_ID: 'Bunke Id',
        BEFORE_ENDRINGER: 'Før endringer',
        AFTER_ENDRINGER: 'Etter endringer',
        COMPARE_POSTER: 'Sammenligne poster',
        BEHANDLE_VALGTE: 'Behandle valgte poster',
        MVA_BUNKE: 'Bunken har avvik grunnet MVA',
        VIS_FOR_MARKERTE: 'Summering for markerte poster',
        EKSPORTER_BUNKER: 'Eksporter bunker',
        SHOW_BEHANDLEDE: 'Vis behandlede poster i bunken',
        BRUKER: 'Bruker',
        MODAL_MESSAGE: 'De markerte postene går ikke i 0. Er du sikker på at du vil eliminere de valgte postene?',
        FILL_FIELD_BUTTON: 'Fyll med utestående beløp',
        BUNKE_COUNTER: (bunkeId, bunkeIds) => `Bunke ${bunkeIds.indexOf(bunkeId) + 1} av ${bunkeIds.length}`,
        BUNKE_HISTORIKK_HEADER: 'Manuelt eliminerte bunker',
    },
    SPINNER: {
        LOADING_DATA: 'Laster data...',
        WORKING: 'Jobber...',
    },
    ADMIN: {
        PERIODER: 'Perioder',
        EKSPORTER_TIL_HFM: 'Lag ny eksport til HFM',
        APPROVE_EKSPORT: 'Godkjenn eksport og åpne neste kvartal',
        EXPORT_HEADER: 'Eksport og åpning av nytt kvartal',
        APPROVE_EKSPORT_INFO: 'Kan ikke åpne neste kvartal før inneværende kvartal er ferdig.',
        EXPORT_CONFIRMATION: 'Er du sikker på at du ønsker å godkjenne eksporten og åpne neste kvartal?',
        EXPORT_WARNING: 'Dette er ikke en handling du kan angre.',
        EXPORT_ID: 'Eksport-id:',
        QUARTER: 'Kvartal:',
        CREATED: 'Opprettet:',
        IS_GENERATING_EXPORT: 'Genererer eksport... Dette kan ta noe tid!',
        LATEST_EXPORT_IS_READY: 'Nyeste eksport er klar for nedlasting! Om rapporten ikke lengre er gyldig, gå til steg 1 og lag en ny eksport før du laster ned og godkjenner.',
        NO_EXPORT_PREVIOUSLY_GENERATED: 'Ingen eksporter har tidligere blitt generert. Trykk "lag eksport" for å generere en.',
        LATEST_GENERATED_EXPORT: 'Siste genererte eksport',
        EXPORT_STEPS: ['Lag eksport til HFM', 'Last ned eksport', 'Godkjenn eksport og åpne neste kvartal'],
        EXPORT_STEPS_BUTTON_TEXT: ['Lag eksport', 'Last ned siste eksport', 'Godkjenn og åpne neste kvartal'],
        SELECT_REGEL_HEADER: 'Velg regel for gjenoppretting',
        CONFIRM_GJENOPPRETTING: 'Gjenopprett poster',
        CONFIRM_KJOR_REGEL: 'Kjør regel',
        SLETTEREGLER_GJENOPPRETT_HELPTEXT: 'Velg en regel og en dato for å gjenopprette alle poster som har blitt slettet med valgt regel fra og med valgt dato.',
        SLETTEREGLER_KJOR_HELPTEXT: 'Velg en regel og en dato for å kjøre valgt sletteregel. Sletteregelen blir kjørt for alle poster opprettet fra og med valgt dato.',
        GJENOPPRETTING_STARTED: 'Gjenoppretting startet, dette kan ta litt tid. Postene som ikke blir avstemt vil dukke opp i '
            + 'avstemming-oversikten når prosessen er ferdig.',
        NEW_SLETTEREGEL: 'Ny sletteregel',
        BETINGELSE: 'Betingelse',
        FROM_DATE: 'Aktiver fra dato',
        RUN_REGEL_MODAL_MESSAGE: amountOfPoster => `Du er i ferd med å kjøre en regel som vil slette ${amountOfPoster} poster. Er du sikker?`,
    },
    FEILTABELL: {
        HEADER: 'Bunker som må kontrolleres',
        OPPHEV_CONFIRMATION: bunkeId => `Er du sikker på at du ønsker å oppheve bunke ${bunkeId}?`,
        REMOVE_BUNKE_FROM_FEILTABELL: 'Fjern bunke fra feiltabell',
    },
    OVERSIKT: {
        EKSPORTER_TIL_KONSERNKONTO: 'Eksporter til Konsernkonto',
        OPPHEV_ELIMINERING: 'Opphev eliminering',
        EKSPORTER_GRUNNLAG: 'Eksporter datagrunnlag',
        YES: 'Ja',
        NO: 'Nei',
        MANUAL: 'Manuell',
        AUTOMATIC: 'Automatisk',
        EXPANDED_SEARCH: 'Utvidet søk',
        SELSKAP_NR: 'SelskapNr (kommaseparert)',
        MOTPART_NR: 'MotpartNr (kommaseparert)',
        POSTTYPE: 'Posttype',
        AVSTEMMING_TYPE: 'Avstemmmingtype',
        REGEL_ID: 'Regel-id',
        KONTONR_FRA: 'Kontonr fra',
        KONTONR_TIL: 'Kontonr til',
        KONSERNKONTONR_FRA: 'Konsernkontonr fra',
        KONSERNKONTONR_TIL: 'Konsernkontonr til',
        FAKTURANR: 'FakturaNr (kommaseparert)',
        HOVEDKILDE: 'Hovedkilde (kommaseparert)',
        BRUTTOBELOP_FRA: 'Bruttobeløp fra',
        BRUTTOBELOP_TIL: 'Bruttobeløp til',
        BELOP_FRA: 'Beløp fra',
        BELOP_TIL: 'Beløp til',
        BUNKE_ID: 'Bunke id',
        SEARCH: 'Søk',
        OPPHEV_BUNKER: 'Opphev bunker',
        MODAL_MESSAGE: amountOfBunker => `Du er i ferd med å oppheve avstemming for ${amountOfBunker} bunke(r). Er du sikker?`,
    },
};

export default NOB;
